import { CategoryWithAttributes } from "../../../api/staff_attributes";

interface StaffAttributesViewProps {
  staffAttributes: CategoryWithAttributes[];
}

const StaffAttributesView = ({ staffAttributes }: StaffAttributesViewProps ) => {
  const colors = ['#EC7177', '#7EC08A', '#EFC24D', '#64B5F6'];
  const getColorByIndex = (index: number) => {
    return colors[index % colors.length];
  };
  return (
    <>
      <div className="details-block">
        <h3>働くスタッフについて</h3>
        <div className="staff-attributes-container">
          {staffAttributes.map((attribute) => (
            <div 
              key={attribute.id}
              className="staff-attribute-item-container"
            >
              <p className="staff-attribute-label">◆{attribute.displayName}の割合</p>
              <table className="staff-attributes-table">
                <tbody>
                  <tr>
                    {attribute.staffAttributes
                      .filter(staffAttribute => staffAttribute.percentage > 0)
                      .map((staffAttribute, index) => (
                        <td
                          key={staffAttribute.id}
                          className={`staff-attribute-item ${index > 0 ? 'with-border' : ''}`}
                          style={{ width: `${staffAttribute.percentage}%`, backgroundColor: getColorByIndex(index) }}
                        >
                          <span className="staff-attribute-percentage">{staffAttribute.percentage}%</span>
                        </td>
                      ))}
                  </tr>
                  <tr>
                    {attribute.staffAttributes
                      .filter(staffAttribute => staffAttribute.percentage > 0)
                      .map((staffAttribute) => (
                        <td
                          key={staffAttribute.id}
                          className="staff-attribute-name"
                        >
                          {staffAttribute.displayName}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StaffAttributesView;