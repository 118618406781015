import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { Prefecture, fetchPrefectures } from '../../api/prefectures';
import { SelectOption } from '../../types';
import { fetchCities } from '../../api/cities';

type PrefCitySelectProps = {
  selectedPrefecture: string;
  selectedCities: SelectOption[];
  onPrefectureChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onCitiesChange: (selectedOptions: MultiValue<SelectOption>) => void;
};

export const PrefCitySelect = ({
  selectedPrefecture,
  selectedCities,
  onPrefectureChange,
  onCitiesChange
}: PrefCitySelectProps) => {
  const [prefectureOptions, setPrefectureOptions] = useState<Prefecture[]>([]);
  const [cityOptions, setCityOptions] = useState<SelectOption[]>([]);

  //プルダウンのprefectureを取得する
  useEffect(() => {
    (async () => {
      const { data } = await fetchPrefectures();
      setPrefectureOptions(data);
    })();
  }, []);

  //プルダウンの都道府県に基づくcityを取得する
  useEffect(() => {
    (async () => {
      if (selectedPrefecture) {
        const { data } = await fetchCities({ url_word: selectedPrefecture });
        const cityOptionsData = data.map(({ name, code }) => ({
          value: code,
          label: name,
        }));
        setCityOptions(cityOptionsData);
      } else {
        setCityOptions([]);
      }
    })();
  }, [selectedPrefecture]);

  return (
    <div
      className={'parts-block col2-block-pc'}
      style={{ overflow: 'unset' }}
    >
      <div className={'select-group'}>
        <FontAwesomeIcon
          icon={faChevronDown}
          aria-hidden={'true'}
          className={'fa'}
        />
        <select
          value={selectedPrefecture}
          name={'prefecture_url_word'}
          id={'prefectures-select'}
          className={'select-box clearable'}
          onChange={onPrefectureChange}
        >
          <option value=''>都道府県を選択</option>
          {prefectureOptions.map((prefecture) => (
            <option
              key={prefecture.name}
              value={prefecture.urlWord}
            >
              {prefecture.name}
            </option>
          ))}
        </select>
      </div>
      <div
        className={'react-select-group'}
      >
        <Select
          value={selectedCities}
          isMulti
          isDisabled={!selectedPrefecture}
          name={'city_code[]'}
          options={cityOptions}
          className={'basic-multi-select'}
          classNamePrefix={'select'}
          onChange={onCitiesChange}
          placeholder={'市区町村を選択'}
          styles={{
            control: (base) => ({
              ...base,
              borderColor: '#E2D8C8',
              fontSize: '14px',
              minHeight: '40px'
            }),
          }}
        />
      </div>
    </div>
  );
};
