interface FavoriteJobLinkProps {
  favoriteJobsCount: number;
}

const FavoriteJobLink = ({ favoriteJobsCount }: FavoriteJobLinkProps) => {
  const style = {
    marginLeft: '4px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: favoriteJobsCount >= 100 ? '32px' : favoriteJobsCount > 9 ? '24px' : '20px',
    width: favoriteJobsCount >= 100 ? '32px' : favoriteJobsCount > 9 ? '24px' : '20px',
  };

  return (
    <a href="/jobs/favorites">
      ❤️ 気になる
      <span
        className="badge"
        style={style}>{favoriteJobsCount >= 100 ? '99+' : favoriteJobsCount}</span>
    </a>
  );
};

export default FavoriteJobLink;