import { SVGProps } from "react";

const Heart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg 
      width={props.width || "18"}
      height={props.height || "16"}
      viewBox="0 0 18 16" 
      fill={props.fill || "none"} 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        d="M12.9062 0.5C11.293 0.5 9.88047 1.19375 9 2.36641C8.11953 1.19375 6.70703 0.5 5.09375 0.5C3.80955 0.501447 2.57837 1.01223 1.6703 1.9203C0.762235 2.82837 0.251447 4.05955 0.25 5.34375C0.25 10.8125 8.35859 15.2391 8.70391 15.4219C8.79492 15.4708 8.89665 15.4965 9 15.4965C9.10335 15.4965 9.20508 15.4708 9.29609 15.4219C9.64141 15.2391 17.75 10.8125 17.75 5.34375C17.7486 4.05955 17.2378 2.82837 16.3297 1.9203C15.4216 1.01223 14.1904 0.501447 12.9062 0.5ZM9 14.1563C7.57344 13.325 1.5 9.53828 1.5 5.34375C1.50124 4.39101 1.88026 3.47765 2.55396 2.80396C3.22765 2.13026 4.14101 1.75124 5.09375 1.75C6.61328 1.75 7.88906 2.55938 8.42188 3.85938C8.46896 3.97401 8.54907 4.07205 8.65201 4.14105C8.75494 4.21005 8.87607 4.2469 9 4.2469C9.12393 4.2469 9.24506 4.21005 9.34799 4.14105C9.45093 4.07205 9.53104 3.97401 9.57812 3.85938C10.1109 2.55703 11.3867 1.75 12.9062 1.75C13.859 1.75124 14.7724 2.13026 15.446 2.80396C16.1197 3.47765 16.4988 4.39101 16.5 5.34375C16.5 9.53203 10.425 13.3242 9 14.1563Z" 
        fill={props.fill || "#7B6D64"}
      />
    </svg>
  );
};

export default Heart;