import { useEffect, useState } from "react";
import Overlay from "./Overlay";
import GbpReviewList from "./GbpReviewList";
import StaffAttributesView from "./StaffAttributesView";
import StoreManagerCommentView from "./StoreManagerCommentView";
import { GbpReview, fetchGbpReviews } from "../../../api/gbp_reviews";
import { CategoryWithAttributes, fetchStaffAttributes } from "../../../api/staff_attributes";

interface StoreDetailProps {
  isRegistered: boolean;
  storeId: number;
  gbpPlaceId: string | null;
  managerComment: string;
}

const StoreDetail = ({ isRegistered, storeId, gbpPlaceId, managerComment }: StoreDetailProps) => {
  const [gbpReviews, setGbpReviews] = useState<GbpReview[]>([]);
  const [staffAttributes, setStaffAttributes] = useState<CategoryWithAttributes[]>([]);

  const fetchGbpReviewsApi = async () => {
    const response = await fetchGbpReviews({ store_id: storeId });
    return response.data;
  };

  const fetchStaffAttributesApi = async () => {
    const response = await fetchStaffAttributes({ store_id: storeId });
    return response.data;
  };

  const fetchApi = async () => {
    const [gbpReviews, staffAttributes] = await Promise.all([
      fetchGbpReviewsApi(),
      fetchStaffAttributesApi()
    ]);
    setGbpReviews(gbpReviews);
    setStaffAttributes(staffAttributes);
  };

  useEffect(() => {
    fetchApi();
  }, [isRegistered]);

  return (
    <>
      <div className="details-container">
        {isRegistered ? (
          <>
            <GbpReviewList
              gbpReviews={gbpReviews}
              gbpPlaceId={gbpPlaceId} />
            {staffAttributes.length > 0 && <StaffAttributesView staffAttributes={staffAttributes} />}
            {managerComment && <StoreManagerCommentView commentText={managerComment} />}
          </>
        ) : (
          <Overlay />
        )}
      </div>
    </>
  );
};

export default StoreDetail;