import { SVGProps } from "react";

const ExitDoor = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "20"}
      height={props.height || "20"}
      viewBox="0 0 20 20"
      fill={props.fill || "#7B6C64"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.75 16.875C8.75 17.0408 8.68415 17.1997 8.56694 17.3169C8.44973 17.4342 8.29076 17.5 8.125 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H8.125C8.29076 2.5 8.44973 2.56585 8.56694 2.68306C8.68415 2.80027 8.75 2.95924 8.75 3.125C8.75 3.29076 8.68415 3.44973 8.56694 3.56694C8.44973 3.68415 8.29076 3.75 8.125 3.75H3.75V16.25H8.125C8.29076 16.25 8.44973 16.3158 8.56694 16.4331C8.68415 16.5503 8.75 16.7092 8.75 16.875ZM17.3172 9.55781L14.1922 6.43281C14.0749 6.31554 13.9159 6.24965 13.75 6.24965C13.5841 6.24965 13.4251 6.31554 13.3078 6.43281C13.1905 6.55009 13.1247 6.70915 13.1247 6.875C13.1247 7.04085 13.1905 7.19991 13.3078 7.31719L15.3664 9.375H8.125C7.95924 9.375 7.80027 9.44085 7.68306 9.55806C7.56585 9.67527 7.5 9.83424 7.5 10C7.5 10.1658 7.56585 10.3247 7.68306 10.4419C7.80027 10.5592 7.95924 10.625 8.125 10.625H15.3664L13.3078 12.6828C13.1905 12.8001 13.1247 12.9591 13.1247 13.125C13.1247 13.2909 13.1905 13.4499 13.3078 13.5672C13.4251 13.6845 13.5841 13.7503 13.75 13.7503C13.9159 13.7503 14.0749 13.6845 14.1922 13.5672L17.3172 10.4422C17.3753 10.3841 17.4214 10.3152 17.4529 10.2393C17.4843 10.1635 17.5005 10.0821 17.5005 10C17.5005 9.91787 17.4843 9.83654 17.4529 9.76066C17.4214 9.68479 17.3753 9.61586 17.3172 9.55781Z"
        fill={props.fill || "#7B6C64"}
      />
    </svg>
  );
};

export default ExitDoor;