import { SVGProps } from 'react';

const Person = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg 
      width={props.width || "18"}
      height={props.height || "17"}
      viewBox="0 0 18 17" 
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0406 15.5625C15.8508 13.5055 14.0172 12.0305 11.8773 11.3312C12.9358 10.7011 13.7582 9.74099 14.2181 8.59827C14.6781 7.45554 14.7502 6.19343 14.4235 5.00574C14.0967 3.81805 13.3891 2.77045 12.4094 2.02384C11.4296 1.27722 10.2318 0.872864 9 0.872864C7.76818 0.872864 6.57041 1.27722 5.59064 2.02384C4.61087 2.77045 3.90328 3.81805 3.57651 5.00574C3.24975 6.19343 3.32189 7.45554 3.78186 8.59827C4.24183 9.74099 5.06419 10.7011 6.12265 11.3312C3.98281 12.0297 2.14922 13.5047 0.959373 15.5625C0.915739 15.6336 0.886797 15.7128 0.874255 15.7953C0.861712 15.8778 0.865823 15.962 0.886345 16.0429C0.906867 16.1238 0.943384 16.1998 0.993742 16.2663C1.0441 16.3329 1.10728 16.3887 1.17955 16.4304C1.25182 16.4722 1.33172 16.499 1.41454 16.5094C1.49735 16.5198 1.5814 16.5134 1.66173 16.4908C1.74206 16.4682 1.81705 16.4296 1.88225 16.3776C1.94746 16.3255 2.00158 16.2608 2.0414 16.1875C3.51328 13.6437 6.11484 12.125 9 12.125C11.8852 12.125 14.4867 13.6437 15.9586 16.1875C15.9984 16.2608 16.0525 16.3255 16.1177 16.3776C16.183 16.4296 16.2579 16.4682 16.3383 16.4908C16.4186 16.5134 16.5026 16.5198 16.5855 16.5094C16.6683 16.499 16.7482 16.4722 16.8204 16.4304C16.8927 16.3887 16.9559 16.3329 17.0063 16.2663C17.0566 16.1998 17.0931 16.1238 17.1136 16.0429C17.1342 15.962 17.1383 15.8778 17.1257 15.7953C17.1132 15.7128 17.0843 15.6336 17.0406 15.5625ZM4.625 6.49999C4.625 5.6347 4.88159 4.78883 5.36232 4.06937C5.84305 3.3499 6.52633 2.78915 7.32576 2.45802C8.12518 2.12688 9.00485 2.04024 9.85352 2.20905C10.7022 2.37786 11.4817 2.79454 12.0936 3.4064C12.7054 4.01825 13.1221 4.7978 13.2909 5.64647C13.4597 6.49514 13.3731 7.3748 13.042 8.17423C12.7108 8.97366 12.1501 9.65694 11.4306 10.1377C10.7112 10.6184 9.86529 10.875 9 10.875C7.84006 10.8737 6.72798 10.4124 5.90778 9.59221C5.08757 8.77201 4.62624 7.65993 4.625 6.49999Z"
        fill={props.fill || "#7B6D64"}
      />
    </svg>
  );
};

export default Person;
