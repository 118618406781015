import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import { RailroadLine } from "../../../../api/railroad_lines";
import { RailroadStation } from "../../../../api/railroad_stations";
import { mediaQuery, useMediaQuery } from '../../../../hooks/useMediaQuery';

export interface RailloadAndStationSelectorModalComponentProps {
  isOpen: boolean;
  onClose: (event: React.FormEvent<Element>) => void;
  railloadLines: RailroadLine[];
  selectedRailroadLines: RailroadLine[];
  onChangeRailroadLine: (selectedRailroadLine: RailroadLine) => void;
  selectedRailroadStations: RailroadStation[];
  onChangeRailroadStation: (selectedRailroadStation: RailroadStation) => void;
}

interface RailroadLineCheckboxProps {
  line: RailroadLine;
  selectedLines: RailroadLine[];
  onChange: (line: RailroadLine) => void;
}

const RailroadLineCheckbox = ({ line, selectedLines, onChange }: RailroadLineCheckboxProps) => (
  <label>
    <input
      type="checkbox"
      checked={selectedLines.some(selectedLine => selectedLine.id === line.id)}
      onChange={() => onChange(line)}
      name="railroad_line_ids[]"
      value={line.id}
    />
    <span className="railroad-line-name">{line.name}</span>
  </label>
);

interface StationListProps {
  stations: RailroadStation[];
  selectedStations: RailroadStation[];
  onChangeStation: (station: RailroadStation) => void;
  isSp: boolean;
}

const StationList = ({ stations, selectedStations, onChangeStation, isSp }: StationListProps) => (
  <ul className={isSp ? "railroad-station-sp" : "railroad-station"}>
    {stations.map((station) => (
      <li key={station.id}>
        <label>
          <input
            type="checkbox"
            checked={selectedStations.some(s => s.id === station.id)}
            onChange={() => onChangeStation(station)}
            name="railroad_station_ids[]"
            value={station.id}
          />
          {station.stationName}
        </label>
      </li>
    ))}
  </ul>
);

interface CustomToggleProps {
  eventKey: string;
}

const CustomToggle = ({ eventKey }: CustomToggleProps) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      className="btn-unstyled"
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? (
        <img
          src="/img/icon_arrow_up_round.svg"
          alt="collapse" />
      ) :
        <img
          src="/img/icon_arrow_down_round.svg"
          alt="expand" />
      }
    </button>
  );
};

const RailloadAndStationSelectorModalComponent = ({
  isOpen,
  onClose,
  railloadLines, 
  selectedRailroadLines, 
  onChangeRailroadLine, 
  selectedRailroadStations, 
  onChangeRailroadStation
}: RailloadAndStationSelectorModalComponentProps) => {
  const isSp = useMediaQuery(mediaQuery.sp);

  return (
    <>
      <div
        className={'js-modal modal'}
        style={{ display: isOpen ? 'block' : 'none' }}>
        <div
          className="modal-bg"
          onClick={onClose}></div>
        <div className="modal-content">
          <div className="in-box">
            <h2 className="modal-title">路線・駅を選択</h2>
            {isSp ? (
              railloadLines.map((line, index) => (
                <Accordion
                  key={line.id}
                >
                  <Card
                    className="railroad-content-sp">
                    <Card.Header className="railroad-line-sp">
                      <RailroadLineCheckbox
                        line={line}
                        selectedLines={selectedRailroadLines}
                        onChange={onChangeRailroadLine}
                      />
                      <CustomToggle eventKey={String(index)}/>
                    </Card.Header>
                    <Accordion.Collapse eventKey={String(index)}>
                      <StationList
                        stations={line.railroadStations}
                        selectedStations={selectedRailroadStations}
                        onChangeStation={onChangeRailroadStation}
                        isSp={isSp}
                      />
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))
            ) : (
              railloadLines.map((line) => (
                <div
                  key={line.id}
                  className="railroad-content">
                  <RailroadLineCheckbox
                    line={line}
                    selectedLines={selectedRailroadLines}
                    onChange={onChangeRailroadLine}
                  />
                  <StationList
                    stations={line.railroadStations}
                    selectedStations={selectedRailroadStations}
                    onChangeStation={onChangeRailroadStation}
                    isSp={isSp}
                  />
                </div>
              ))
            )}
          </div>
          <button
            className="js-modal-close close-btn btn-unstyled"
            onClick={onClose}
          >×</button>
        </div>
      </div>
    </>
  );
};

export default RailloadAndStationSelectorModalComponent;