/**
 * 数値を通貨形式に変換する
 * @param num 
 * @returns 
 */
export const numberToCurrency = (num: number): string => {
  if (num > 10000) {
    const formattedNumber = (num / 10000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    return `${formattedNumber}万円`;
  } else {
    const formattedNumber = num.toLocaleString();
    return `${formattedNumber}円`;
  }
};