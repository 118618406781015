interface StoreManagerCommentViewProps {
  commentText: string;
}

const StoreManagerCommentView = ({ commentText }: StoreManagerCommentViewProps) => {
  return (
    <div className="details-block">
      <h3>店長からのコメント</h3>
      <div className="summary">
        <div className="add-contents">
          {commentText.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoreManagerCommentView;