import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

type SalaryType = {
  url_word: string;
  name: string;
};

type SalarySelectProps = {
  dropdownOptions: SalaryType[];
  selectedSalaryType: string;
  selectedSalaryMin: string;
  selectedSalaryMax: string;
  handleSalaryTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSalaryMin: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSalaryMax: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SalarySelect = ({
  dropdownOptions,
  selectedSalaryType,
  selectedSalaryMin,
  selectedSalaryMax,
  handleSalaryTypeChange,
  handleSalaryMin,
  handleSalaryMax
}: SalarySelectProps) => {

  const renderLowerLimitOptions = () => {
    switch (selectedSalaryType) {
      case 'hourly':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='800'>800</option>
            <option value='900'>900</option>
            <option value='1000'>1000</option>
            <option value='1200'>1200</option>
            <option value='1500'>1500</option>
            <option value='2000'>2000</option>
          </>
        );
      case 'daily':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='6000'>6000</option>
            <option value='8000'>8000</option>
            <option value='10000'>10000</option>
            <option value='12000'>12000</option>
            <option value='15000'>15000</option>
            <option value='20000'>20000</option>
          </>
        );
      case 'monthly':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='150000'>15万</option>
            <option value='180000'>18万</option>
            <option value='200000'>20万</option>
            <option value='250000'>25万</option>
            <option value='300000'>30万</option>
          </>
        );
      case 'annual':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='1000000'>100万</option>
            <option value='2000000'>200万</option>
            <option value='3000000'>300万</option>
            <option value='4000000'>400万</option>
            <option value='6000000'>600万</option>
            <option value='8000000'>800万</option>
            <option value='10000000'>1000万</option>
          </>
        );
      case 'commission':
        return (
          <>
            <option
              value=''
              disabled
            >指定なし</option>
          </>
        );
      default:
        return <option value=''>指定なし</option>;
    }
  };

  const renderUpperLimitOptions = () => {
    switch (selectedSalaryType) {
      case 'hourly':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='900'>900</option>
            <option value='1000'>1000</option>
            <option value='1200'>1200</option>
            <option value='1500'>1500</option>
            <option value='2000'>2000</option>
            <option value='3000'>3000</option>
          </>
        );
      case 'daily':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='10000'>10000</option>
            <option value='12000'>12000</option>
            <option value='15000'>15000</option>
            <option value='20000'>20000</option>
            <option value='30000'>30000</option>
          </>
        );
      case 'monthly':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='180000'>18万</option>
            <option value='200000'>20万</option>
            <option value='250000'>25万</option>
            <option value='300000'>30万</option>
            <option value='400000'>40万</option>
            <option value='500000'>50万</option>
            <option value='800000'>80万</option>
          </>
        );
      case 'annual':
        return (
          <>
            <option value=''>指定なし</option>
            <option value='1000000'>100万</option>
            <option value='2000000'>200万</option>
            <option value='3000000'>300万</option>
            <option value='4000000'>400万</option>
            <option value='6000000'>600万</option>
            <option value='8000000'>800万</option>
            <option value='10000000'>1000万</option>
          </>
        );
      case 'commission':
        return (
          <>
            <option
              value=''
              disabled
            >指定なし</option>
          </>
        );
      default:
        return <option value=''>指定なし</option>;
    }
  };

  return (
    <div className={'parts-block salary-input'}>
      <div className={'select-group'}>
        <FontAwesomeIcon
          icon={faChevronDown}
          aria-hidden={'true'}
          className={'fa'}
        />
        <select
          id={'salary-select'}
          name={'job_salary_type'}
          className={'select-box clearable'}
          value={selectedSalaryType}
          onChange={handleSalaryTypeChange}
        >
          <option value=''>給与形態を選択</option>
          {dropdownOptions.map((salaryType) => (
            <option
              key={salaryType.url_word}
              value={salaryType.url_word}
            >
              {salaryType.name}
            </option>
          ))}
        </select>
      </div>
      <div className={'salary-detail'}>
        <div className={'select-group'}>
          <FontAwesomeIcon
            icon={faChevronDown}
            aria-hidden={'true'}
            className={'fa'}
          />
          <select
            name={'salary_min'}
            id={'lower-select'}
            className={'select-box clearable'}
            value={selectedSalaryMin}
            onChange={handleSalaryMin}
            disabled={selectedSalaryType === ''}
          >
            {renderLowerLimitOptions()}
          </select>
        </div>
        <p className={'form-text'}>円 〜</p>
        <div className={'select-group'}>
          <FontAwesomeIcon
            icon={faChevronDown}
            aria-hidden={'true'}
            className={'fa'}
          />
          <select
            name={'salary_max'}
            id={'upper-select'}
            className={'select-box clearable'}
            value={selectedSalaryMax}
            onChange={handleSalaryMax}
            disabled={selectedSalaryType === ''}
          >
            {renderUpperLimitOptions()}
          </select>
        </div>
        <p className={'form-text'}>円 </p>
      </div>
    </div>
  );
};
