import { GbpReview } from "../../../api/gbp_reviews";
import { CategoryWithAttributes } from "../../../api/staff_attributes";
import GbpReviewList from "./GbpReviewList";
import StaffAttributesView from "./StaffAttributesView";

const dummyReviews: GbpReview[] = [
  {
    id: 1,
    storeId: 1,
    authorName: "瀬良 太郎",
    profilePhotoUrl: "/img/icon_person_dummy.png",
    rating: 5,
    relativeTimeDescription: "1週間前",
    text: "90分の施術をしていただいたところ、だいぶ楽になりました。ありがとうございます。また行きたいです。",
  },
  {
    id: 2,
    storeId: 1,
    authorName: "瀬良 太郎",
    profilePhotoUrl: "/img/icon_person_dummy.png",
    rating: 4,
    relativeTimeDescription: "2週間前",
    text: "90分の施術をしていただいたところ、だいぶ楽になりました。ありがとうございます。また行きたいです。",
  },
  {
    id: 3,
    storeId: 1,
    authorName: "瀬良 太郎",
    profilePhotoUrl: "/img/icon_person_dummy.png",
    rating: 3,
    relativeTimeDescription: "3週間前",
    text: "90分の施術をしていただいたところ、だいぶ楽になりました。ありがとうございます。また行きたいです。",
  },
];

const dummyCategoryWithStaffAttributes: CategoryWithAttributes[] = [
  {
    id: 1,
    name: 'age',
    displayName: '年齢',
    staffAttributes: [
      {
        id: 1,
        attributeCategoryId: 1,
        storeId: 1,
        attributeName: 'age_20s',
        displayName: '20代',
        percentage: 50
      },
      {
        id: 2,
        attributeCategoryId: 1,
        storeId: 1,
        attributeName: 'age_30s',
        displayName: '30代',
        percentage: 30
      },
      {
        id: 3,
        attributeCategoryId: 1,
        storeId: 1,
        attributeName: 'age_40s',
        displayName: '40代',
        percentage: 10
      },
      {
        id: 4,
        attributeCategoryId: 1,
        storeId: 1,
        attributeName: 'age_50s',
        displayName: '50代〜',
        percentage: 10
      }
    ]
  },
  {
    id: 2,
    name: 'gender',
    displayName: '男女',
    staffAttributes: [
      {
        id: 5,
        attributeCategoryId: 2,
        storeId: 1,
        attributeName: 'female',
        displayName: '女性',
        percentage: 50
      },
      {
        id: 6,
        attributeCategoryId: 2,
        storeId: 1,
        attributeName: 'male',
        displayName: '男性',
        percentage: 50
      }
    ]
  },
  {
    id: 3,
    name: 'experience',
    displayName: '経験者',
    staffAttributes: [
      {
        id: 7,
        attributeCategoryId: 3,
        storeId: 1,
        attributeName: 'no_experience',
        displayName: '未経験者',
        percentage: 70
      },
      {
        id: 8,
        attributeCategoryId: 3,
        storeId: 1,
        attributeName: 'experience',
        displayName: '経験者',
        percentage: 30
      }
    ]
  },
  {
    id: 4,
    name: 'employment',
    displayName: '雇用形態',
    staffAttributes: [
      {
        id: 9,
        attributeCategoryId: 4,
        storeId: 1,
        attributeName: 'part-time',
        displayName: 'アルバイト',
        percentage: 10
      },
      {
        id: 10,
        attributeCategoryId: 4,
        storeId: 1,
        attributeName: 'regular',
        displayName: '正社員',
        percentage: 20
      },
      {
        id: 11,
        attributeCategoryId: 4,
        storeId: 1,
        attributeName: 'contract',
        displayName: '契約社員',
        percentage: 30
      },
      {
        id: 12,
        attributeCategoryId: 4,
        storeId: 1,
        attributeName: 'subcontract',
        displayName: '業務委託',
        percentage: 40
      }
    ]
  }  
];

const Overlay = () => {
  return (
    <>
      <div className="overlay">
        <div className="overlay-background" />
        <div className="overlay-content">
          <p className="title">会員登録をすると以下の情報がご覧いただけます</p>
          <div className="item-container">
            <p>・店舗の口コミ情報</p>
            <p>・働くスタッフの割合(男女比、年代別など)</p>
          </div>
          <div className="account-container">
            <a
              href="/users/new"
              className="btn">
                無料で会員登録する
            </a>
            <a 
              href="/sessions/new"
              className="login-link"
            >
              <span className="login-text">ログインは</span>
              <span className="login-highlight">こちら</span>
            </a>
          </div>
        </div>
      </div>
      <GbpReviewList
        gbpReviews={dummyReviews}
        gbpPlaceId={"*********"}
      />
      <StaffAttributesView
        staffAttributes={dummyCategoryWithStaffAttributes}
      />
    </>
  );
};

export default Overlay;