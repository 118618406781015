import { useEffect, useState } from "react";
import { getSessionStatus, postLogout } from "../../../api/users";
import AuthLink from "./AuthLink";

const AuthLinkContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);

  const onClickLogout = async () => {
    try {
      await postLogout();
      window.location.href = '/';
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionStatusApi = async () => {
    setIsLoading(true);
    const response = await getSessionStatus();
    setIsLogin(response.data.isLogin);
    setIsLoading(false);
  };

  useEffect(() => {
    getSessionStatusApi();
  }, []);

  if (isLoading) return <></>;

  return (
    <AuthLink
      isLogin={isLogin}
      onClickLogout={onClickLogout}
    />
  );
};

export default AuthLinkContainer;
