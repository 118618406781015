import { withHttpError } from '../libs/httpRequest/withHttpError';
import {
  GetListResponse,
} from './api';
import { myAxios } from '../libs/httpRequest/myAxios';

export type GbpReview = {
  id: number;
  storeId: number;
  authorName: string;
  profilePhotoUrl: string;
  rating: number;
  relativeTimeDescription: string;
  text: string;
}

export type GbpReviewtParams = {
  store_id: number;
}

export const fetchGbpReviews = async (params: GbpReviewtParams): Promise<GetListResponse<GbpReview>> => {
  const response = await withHttpError<GetListResponse<GbpReview>>(async () => {
    return await myAxios.get(`/api/v1/stores/get_gbp_reviews`, {
      params
    });
  });
  return response.data; 
};