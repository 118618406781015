import { useEffect, useState } from "react";
import { fetchFavoriteJobsCount } from "../../../api/favorite_jobs";
import FavoriteJobLink from "./FavoriteJobLink";

const FavoriteJobLinkContainer = () => {
  const [favoriteJobsCount, setFavoriteJobsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFavoriteJobsCountApi = async () => {
    try {
      const response = await fetchFavoriteJobsCount();
      setFavoriteJobsCount(response.data.count);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavoriteJobsCountApi();

    const handleFavoriteJobUpdated = () => {
      fetchFavoriteJobsCountApi();
    };
    // お気に入りの求人が更新された際に、再取得する
    window.addEventListener('favoriteJobUpdated', handleFavoriteJobUpdated);
    return () => {
      window.removeEventListener('favoriteJobUpdated', handleFavoriteJobUpdated);
    };
  }, []);

  return !isLoading ? <FavoriteJobLink favoriteJobsCount={favoriteJobsCount} /> : null;
};

export default FavoriteJobLinkContainer;
