import { withHttpError } from '../libs/httpRequest/withHttpError';
import { myAxios } from '../libs/httpRequest/myAxios';
import { GetOneResponse } from './api';

export type LoginData = {
  email: string;
  password: string;
}

export type EditLoginData = {
  email: string;
  current_password: string;
  new_password: string | null;
}

export type BasicInfoData = {
  token: string;
  name: string;
  name_kana: string;
  gender: string;
  tel: string;
  birthday: {
    year: string;
    month: string;
    day: string;
  };
  postal_code: string;
  address: string;
}

export type TransformedBasicInfoData = Omit<BasicInfoData, 'birthday'> & {
  birthday: string;
};

export type TransformedBasicInfoDataWithoutToken = Omit<BasicInfoData, 'token' | 'birthday'> & {
  birthday: string;
};

export type RegistrationData = {
  work_experience: string;
  is_receive_scout_mail: 0 | 1;
  prefecture_ids: number[];
  city_ids: number[];
  employment_type_ids: number[];
}

export type SessionStatusResponse = {
  isLogin: boolean;
}

export const postPreRegister = async (params: LoginData): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.post('/api/v1/users/pre_registration', params);
  });
};

export const postBasicInfo = async (params: TransformedBasicInfoData): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.post('/api/v1/users/basic_info', params);
  });
};

export const updateBasicInfo = async (params: TransformedBasicInfoDataWithoutToken): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.put('/api/v1/users/update_basic_info', params);
  });
};

export const postRegistration = async (params: RegistrationData): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.post('/api/v1/users/registration', params);
  });
};

export const upadteRegistration = async (params: RegistrationData): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.put('/api/v1/users/update_application_conditions', params);
  });
};

export const postLogout = async (): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.post('/api/v1/users/logout');
  });
};

export const getSessionStatus = async (): Promise<GetOneResponse<SessionStatusResponse>> => {
  const response = await withHttpError<GetOneResponse<SessionStatusResponse>>(async () => {
    return await myAxios.get('/api/v1/users/session_status');
  });
  return response.data;
};

export const updateEmail = async (params: Omit<EditLoginData, 'new_password'>): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.put('/api/v1/users/update_email', { 
      email: params.email,
      current_password: params.current_password
    });
  });
};

export const updatePassword = async (params: Omit<EditLoginData, 'email'>): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.put('/api/v1/users/update_password', {
      current_password: params.current_password,
      new_password: params.new_password
    });
  });
};
