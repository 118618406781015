import { useState, useEffect } from "react";
import Person from "../../../components/svg/Person";
import Heart from "../../../components/svg/Heart";
import Information from "../../../components/svg/Information";
import ExitDoor from "../../../components/svg/ExitDoor";
import { mediaQuery, useMediaQuery } from "../../../hooks/useMediaQuery";
import { postLogout } from "../../../api/users";

interface Props {
  userName: string;
}

const NavigationMenu = ({ userName }: Props) => {
  const isSp = useMediaQuery(mediaQuery.sp);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const navLinks = [
    {
      id: 1,
      icon: Person,
      label: 'マイページ',
      url: '/mypages',
      showInSp: false,
    },
    // {
    //   id: 2,
    //   icon: ArrowUpLeft,
    //   label: '応募中の求人',
    //   url: '#',
    //   showInSp: true,
    // },
    {
      id: 3,
      icon: Heart,
      label: '気になる',
      url: '/jobs/favorites',
      showInSp: true,
    },
    {
      id: 4,
      icon: Information,
      label: 'プロフィール',
      url: '/mypages/profile',
      showInSp: true,
    },
    {
      id: 5,
      icon: ExitDoor,
      label: 'ログアウト',
      url: '#',
      showInSp: false,
      isLogout: true,
      onClick: async () => {
        try {
          await postLogout();
          window.location.href = '/';
        } catch (error) {
          console.error(error);
        }
      }
    }
  ];

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const currentNavLink = navLinks.find(link => link.url === currentUrl);
    if (currentNavLink) {
      setSelectedId(currentNavLink.id);
    }
  }, [window.location.pathname]);

  return (
    <>
      <div className="navigation-menu-contents">
        {isSp && (
          <h1 className="main-headline">{userName}さんのマイページ</h1>
        )}
        <nav>
          <ul>
            {navLinks.map((navLink) => {
              if (isSp && !navLink.showInSp) {
                return <></>;
              }
              const Icon = navLink.icon;
              const isActive = selectedId === navLink.id;
              return (
                <li 
                  key={navLink.id}
                  className="nav-item"
                >
                  <a 
                    href={navLink.url}
                    onClick={(event) => {
                      if (navLink.isLogout) {
                        event.preventDefault;
                        navLink.onClick();
                      } else {
                        setSelectedId(navLink.id);
                      }
                    }}
                    className={`${isActive ? 'active' : ''}`}
                  >
                    <Icon
                      width={`${isSp ? 24 : 20}`}
                      height={`${isSp ? 24 : 20}`}
                      className="icon"
                      fill={isActive ? '#F29F02' : '#7B6C64'}
                    />
                    <span className="nav-label">{navLink.label}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default NavigationMenu;