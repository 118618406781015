import { useEffect, useState } from 'react';
import { FavoriteJobsRequestParams, fetchFavoriteJobs, registerFavoriteJob, deleteFavoriteJob } from "../../../api/favorite_jobs";
import FavoriteJobRegisterButton from "./FavoriteJobRegisterButton";
import { emitFavoriteJobUpdatedEvent } from "../../../utils/favoriteJobEvents";
import { ShowToast } from '../../../components/toast/ShowToast';

interface FavoriteJobRegisterButtonContainerProps {
  jobPostingId: number;
  favoriteJobPostings: { id: number; jobPostingId: number }[];
}

const FavoriteJobRegisterButtonContainer = ({ jobPostingId, favoriteJobPostings }: FavoriteJobRegisterButtonContainerProps) => {
  /**
   * 気になるリストに求人を登録する
   * @param jobPostingId 
   */
  const [registeredJob, setRegisteredJob] = useState<{ id: number; } | null>(null);
  const [showToast, setShowToast] = useState(false);

  const fetchFavoriteJobsApi = async () => {
    try {
      const response = await fetchFavoriteJobs();
      const foundJob = response.data.find(job => job.jobPosting.id === jobPostingId);
      setRegisteredJob(foundJob ? { id: foundJob.id } : null);
    } catch (error) {
      console.error('データの取得に失敗しました:', error);
    }
  };

  useEffect(() => {
    const foundJob = favoriteJobPostings.find(job => job.jobPostingId === jobPostingId);
    setRegisteredJob(foundJob ? { id: foundJob.id } : null);
  }, []); 

  const onClickRegisterFavoriteJob = async (jobPostingId: number) => {
    const requestParam: FavoriteJobsRequestParams = {
      job_posting_id: jobPostingId,
    };
    try {
      await registerFavoriteJob(requestParam);
      fetchFavoriteJobsApi();
      emitFavoriteJobUpdatedEvent();
      setShowToast(true);
    } catch (error) {
      console.error('データの登録に失敗しました:', error);
    }
  };

  const onClickDeleteFavoriteJob = async (favoriteJobId: number) => {
    try {
      await deleteFavoriteJob(favoriteJobId);
      fetchFavoriteJobsApi();
      emitFavoriteJobUpdatedEvent();
    } catch (error) {
      console.error('データの削除に失敗しました:', error);
    }
  };

  return (
    <>
      {registeredJob ? (
        <button
          className="btn border-btn"
          onClick={() => onClickDeleteFavoriteJob(registeredJob.id)}>
          気になるから外す
        </button>
      ) : (
        <FavoriteJobRegisterButton
          jobPostingId={jobPostingId}
          onClickRegisterFavoriteJob={onClickRegisterFavoriteJob} />
      )}
      {showToast && <ShowToast toastMessage={{ message: '気になるリストに登録しました！', type: 'success' }} />}
    </>
  );
};

export default FavoriteJobRegisterButtonContainer;
