import { withHttpError } from '../libs/httpRequest/withHttpError';
import {
  GetListResponse,
} from './api';
import { myAxios } from '../libs/httpRequest/myAxios';

export type AttributeCategory = {
  id: number;
  name: string;
  displayName: string;
}

export type StaffAttribute = {
  id: number;
  attributeCategoryId: number;
  storeId: number;
  attributeName: string;
  displayName: string;
  percentage: number;
}

export type CategoryWithAttributes = AttributeCategory & {
  staffAttributes: StaffAttribute[];
}

export type StaffAttributeParams = {
  store_id: number;
}

export const fetchStaffAttributes = async (params: StaffAttributeParams): Promise<GetListResponse<CategoryWithAttributes>> => {
  const response = await withHttpError<GetListResponse<CategoryWithAttributes>>(async () => {
    return await myAxios.get(`/api/v1/stores/get_staff_attributes`, {
      params
    });
  });
  return response.data; 
};
