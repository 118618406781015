interface FavoriteJobRegisterButtonProps {
  jobPostingId: number;
  onClickRegisterFavoriteJob: (jobPostingId: number) => void;
}

const FavoriteJobRegisterButton = ({ jobPostingId, onClickRegisterFavoriteJob }: FavoriteJobRegisterButtonProps) => {
  return (
    <button
      className="btn border-btn"
      onClick={() => onClickRegisterFavoriteJob(jobPostingId)}>
        ❤️ 気になる
    </button>
  );
};

export default FavoriteJobRegisterButton;