import { withHttpError } from '../libs/httpRequest/withHttpError';
import { myAxios } from '../libs/httpRequest/myAxios';
import { RailroadStation } from './railroad_stations';

export type RailroadLine = {
  id: number;
  railroadCompanyId: number;
  name: string;
  nameKana: string;
  formalName: string;
  priorityName: string | null;
  priorityNameKana: string | null;
  sortNo: number;
  railroadStations: RailroadStation[];
}

export type RailroadLinesRequestParams = {
  url_word?: string;
}

export type RailroadLinesResponse = {
  data: RailroadLine[];
}

export const fetchRailroadLinesByPrefectureUrlWord = async (params: RailroadLinesRequestParams): Promise<RailroadLinesResponse> => {
  const response = await withHttpError<RailroadLinesResponse>(async () => {
    return await myAxios.get('/api/v1/railroad_lines/get_railroad_lines', {
      params
    });
  });
  return response.data;
};
