import { mediaQuery, useMediaQuery } from "../../../hooks/useMediaQuery";

interface Props {
  userName: string;
}

const MyPageTitle = ({ userName }: Props) => {
  const isPc = useMediaQuery(mediaQuery.pc);
  return (
    <>
      {isPc && <h1 className="main-headline">{userName}さんのマイページ</h1>}
    </>
  );
};

export default MyPageTitle;