import { useState, useEffect } from "react";

type Props = {
  keyword: string;
  delay?: number;
};

export const useDebouncedKeyword = ({ keyword, delay = 1000 }: Props) => {
  const [debouncedKeyword, setDebouncedKeyword] = useState<string>(keyword ?? "");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [keyword, delay]);

  return debouncedKeyword;
};
