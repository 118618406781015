import {
  ChangeEvent,
} from "react";
import { CheckBoxItem } from "../../types/jobPostingSearch";

type CheckBoxGroupProps = {
  selectedValues: string[];
  onCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checkBoxOptions: CheckBoxItem[];
  label: string;
};

export const CheckBoxGroup = ({
  label,
  checkBoxOptions,
  selectedValues,
  onCheckBoxChange
}: CheckBoxGroupProps) => {

  return(
    <div className={'parts-block'}>
      <div className={'form-inline-box equal'}>
        {checkBoxOptions.map((item) => (
          <div
            className={'checkbox checkbox-btn'}
            key={item.url_word}>
            <input
              type={'checkbox'}
              id={`${label}_${item.url_word}`}
              name={`${label}[]`}
              value={item.url_word}
              multiple
              hidden={false}
              checked={
                selectedValues.includes(item.url_word)
              }
              onChange={onCheckBoxChange}
              className={'checkbox-input'}
            />
            <label
              htmlFor={`${label}_${item.url_word}`}
              className="checkbox-label"
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
