import { GbpReview } from "../../../api/gbp_reviews";

interface GbpReviewProps {
  gbpReviews: GbpReview[];
  gbpPlaceId: string | null;
}

const GbpReviewList = ({ gbpReviews, gbpPlaceId }: GbpReviewProps) => {
  return (
    <>
      <div className="details-block">
        <h3>店舗の口コミ情報</h3>
        <div className="review-container">
          {gbpReviews.length === 0 ? (
            <div className="no-review">口コミがありません</div>
          ) : (
            <>
              {gbpReviews.map((gbpReview) => (
                <div
                  key={gbpReview.id}
                  className="review-item"
                >
                  <div className="review-header">
                    <img 
                      src={gbpReview.profilePhotoUrl}
                      onError={(e) => {
                        e.currentTarget.src = "/img/icon_person_dummy.png";
                      }}
                      alt="profile photo"
                      className="profile-photo"
                    />
                    <span className="author-name">{gbpReview.authorName}</span>
                  </div>
                  <div className="rating">{"★".repeat(gbpReview.rating)}</div>
                  <div className="review-text">{gbpReview.text}</div>
                  <div className="relative-time">{gbpReview.relativeTimeDescription}</div>
                </div>
              ))}
              {gbpPlaceId && (
                <div className="view-all-reviews">
                  <a
                    href={`https://www.google.com/maps/place/?q=place_id:${gbpPlaceId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  ≫全てのクチコミを見る
                  </a>
                  <p className="external-link-notice">※外部サイトへ遷移します</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GbpReviewList;