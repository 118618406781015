import Information from "../../../components/svg/Information";
import { ProfileData } from "../../../api/profiles";

const employmentTypeOptions = [
  { value: 1, label: "アルバイト" },
  { value: 2, label: "正社員" },
  { value: 3, label: "契約社員" },
  { value: 4, label: "業務委託" }
];

const workExperienceOptions = [
  { value: "no_experience", label: "未経験" },
  { value: "less_than_one_year", label: "実務経験1年未満" },
  { value: "one_to_three_years", label: "実務経験1年以上3年未満" },
  { value: "more_than_three_years", label: "実務経験3年以上" },
  { value: "more_than_five_years", label: "実務経験5年以上" },
  { value: "more_than_ten_years", label: "実務経験10年以上" }
];

const Profile = (props: ProfileData) => {
  const getGender = (gender: string) => {
    return gender === 'male' ? '男性' : gender === 'female' ? '女性' : gender;
  };

  const getEmploymentTypes = (ids: number[]) => {
    return ids.map(id => employmentTypeOptions.find(option => option.value === id)?.label).join('・');
  };

  const getWorkExperience = (value: string) => {
    return workExperienceOptions.find(option => option.value === value)?.label;
  };

  const getReceiveScoutMail = (value: boolean) => {
    return value ? '受け取る' : '受け取らない';
  };

  return (
    <>
      <div className="icon-title-container">
        <Information
          width={24}
          height={24}
          fill="#F29F02"
          className="icon"
        />
        <h1 className="main-headline">プロフィール</h1>
      </div>
      <div className="profile-headline">
        <div className="profile-header">
          <h1 className="main-headline">基本情報</h1>
          <a
            href="/mypages/basic_info/edit" 
            className="main-headline edit-link">編集する</a>
        </div>
        <div className="form-box">
          <div className="form-block">
            <div className="label-block">
              <label>氏名</label>
            </div>
            <div className="parts-block">
              {props.name}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>カナ</label>
            </div>
            <div className="parts-block">
              {props.name_kana}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>性別</label>
            </div>
            <div className="parts-block">
              {getGender(props.gender)}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>電話番号</label>
            </div>
            <div className="parts-block">
              {props.tel}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>生年月日</label>
            </div>
            <div className="parts-block">
              {props.birthday}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>郵便番号</label>
            </div>
            <div className="parts-block">
              {props.postal_code}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>住所</label>
            </div>
            <div className="parts-block">
              {props.address}
            </div>
          </div>
        </div>
      </div>
      <div className="profile-headline">
        <div className="profile-header">
          <h1 className="main-headline">ログイン情報</h1>
          <a 
            href="/mypages/login/edit"
            className="main-headline edit-link">編集する</a>
        </div>
        <div className="form-box">
          <div className="form-block">
            <div className="label-block">
              <label>メールアドレス</label>
            </div>
            <div className="parts-block">
              {props.email}
            </div>
          </div>
        </div>
      </div>
      <div className="profile-headline">
        <div className="profile-header">
          <h1 className="main-headline">応募条件</h1>
          <a
            href="/mypages/requirements/edit"
            className="main-headline edit-link">編集する</a>
        </div>
        <div className="form-box">
          <div className="form-block">
            <div className="label-block">
              <label>希望勤務エリア</label>
            </div>
            <div className="parts-block">
              {props.prefecture_names.length > 0 && (
                <p className="prefecture">{props.prefecture_names.join('・')}</p>
              )}
              {props.city_names.length > 0 && (
                <p>{props.city_names.join('・')}</p>
              )}
              {props.prefecture_names.length === 0 && props.city_names.length === 0 && (
                <p>指定なし</p>
              )}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>希望雇用形態</label>
            </div>
            <div className="parts-block">
              {getEmploymentTypes(props.employment_type_ids)}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>経験</label>
            </div>
            <div className="parts-block">
              {getWorkExperience(props.work_experience)}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>スカウトメール</label>
            </div>
            <div className="parts-block">
              {getReceiveScoutMail(props.is_receive_scout_mail)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;