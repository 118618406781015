import { useEffect, useState } from "react";
import FavoriteJobComponent from "./FavoriteJobComponent";
import { FavoriteJob } from "../../../api/favorite_jobs";
import { fetchFavoriteJobs, deleteFavoriteJob } from "../../../api/favorite_jobs";
import { NearestRailroadStation } from "../../../api/nearest_railroad_stations";
import { EmploymentType, JobPostingSalary, SalaryType } from "../../../api/job_posting_salaries";
import { emitFavoriteJobUpdatedEvent } from "../../../utils/favoriteJobEvents";

const FavoriteJobContainer = () => {
  const [favoriteJobs, setFavoriteJobs] = useState<FavoriteJob[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // NOTE: 本来はここで変換処理を行うべきではない
  const getEmploymentTypeText = (employmentType: EmploymentType) => {
    switch (employmentType) {
      case "part-time":
        return "アルバイト";
      case "regular":
        return "正社員";
      case "contract":
        return "契約社員";
      case "subcontract":
        return "業務委託";
    }
  };

  // NOTE: 本来はここで変換処理を行うべきではない
  const getSalaryTypeText = (salaryType: SalaryType) => {
    switch (salaryType) {
      case "hourly":
        return "時給";
      case "daily":
        return "日給";
      case "monthly":
        return "月給";
      case "annual":
        return "年俸";
      case "commission":
        return "歩合";
    }
  };

  /**
   * 最寄り駅をソートする
   * @param nearestRailroadStations 
   * @returns 
   */
  const sortedNearestRailroadStations = (nearestRailroadStations: NearestRailroadStation[]) => {
    return nearestRailroadStations.sort((a, b) => {
      const sortNoA = a.sortNo !== null ? a.sortNo : Infinity;
      const sortNoB = b.sortNo !== null ? b.sortNo : Infinity;
      if (sortNoA !== sortNoB) {
        return sortNoA - sortNoB;
      }
      return a.id - b.id;
    });
  };

  /**
   * お気に入りの求人を取得して、stateにセットする
   */
  const fetchFavoriteJobsApi = async () => {
    setIsLoading(true);
    const response = await fetchFavoriteJobs();
    setFavoriteJobs(response.data);
    setIsLoading(false);
  };

  /**
   * お気に入りの求人を削除して、再取得と更新通知のイベントを発火する
   * @param favoriteJobId 
   */
  const onClickDeleteFavoriteJob = async (favoriteJobId: number) => {
    await deleteFavoriteJob(favoriteJobId);
    fetchFavoriteJobsApi();
    // 気になるリストの件数を更新するためのイベントを発火する
    emitFavoriteJobUpdatedEvent();
  };

  /**
   * 一括応募ページへ遷移するためのURLを構築する
   * @param codes 
   * @returns 
   */
  const buildBulkJobPostingUrl = (codes: string[]) => {
    const query = codes.map(code => `codes[]=${code}`).join('&');
    return codes.length > 0 ? `/application/multi?${query}` : '#';
  };

  useEffect (() => {
    fetchFavoriteJobsApi();
  }, []);

  if (isLoading) return <></>;

  // 加工したデータを構築してコンポーネントに渡す
  const enhancedFavoriteJobs: FavoriteJob[] = favoriteJobs.map((favoriteJob) => {
    const jobPostingSalariesWithText: JobPostingSalary[] = favoriteJob.jobPosting.jobPostingSalaries.map((jobPostingSalary) => ({
      ...jobPostingSalary,
      employmentTypeText: getEmploymentTypeText(jobPostingSalary.employmentType),
      salaryTypeText: getSalaryTypeText(jobPostingSalary.salaryType),
    }));

    return {
      ...favoriteJob,
      jobPosting: {
        ...favoriteJob.jobPosting,
        jobPostingSalaries: jobPostingSalariesWithText,
        nearestRailroadStations: sortedNearestRailroadStations(favoriteJob.jobPosting.nearestRailroadStations),
      },
    };
  });

  return (
    <FavoriteJobComponent
      favoriteJobs={enhancedFavoriteJobs}
      bulkJobPostingUrl={buildBulkJobPostingUrl(favoriteJobs.map(favoriteJob => favoriteJob.jobPosting.code))}
      onClickDeleteFavoriteJob={onClickDeleteFavoriteJob} />

  );
};

export default FavoriteJobContainer;
