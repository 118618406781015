import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { EditLoginData, LoginData, updateEmail, updatePassword } from "../../../api/users";

const EditLoginForm = (props: LoginData) => {
  const [error, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EditLoginData>({
    defaultValues: {
      ...props
    }
  });

  const onSubmit: SubmitHandler<EditLoginData> = async (data) => {
    try {
      if (data.email !== props.email) {
        await updateEmail({ 
          email: data.email, 
          current_password: data.current_password 
        });
      }

      if (data.new_password) {
        await updatePassword({
          current_password: data.current_password,
          new_password: data.new_password
        });
      }

      window.location.href = "/mypages";
    } catch (error) {
      setError("現在のパスワードが正しくありません。");
    }
  };

  const [isRevealCurrentPassword, setIsRevealCurrentPassword] = useState(false);
  const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);

  const toggleCurrentPassword = () => {
    setIsRevealCurrentPassword(!isRevealCurrentPassword);
  };

  const toggleNewPassword = () => {
    setIsRevealNewPassword(!isRevealNewPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-login-headline">
        <h1 className="main-headline">ログイン情報変更</h1>
        <div className="form-box">
          <div className="form-block">
            <div className="label-block">
              <label>メールアドレス<span>※必須</span></label>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <input 
                  id="email"
                  type="text"
                  className="input-text name required" 
                  placeholder="taro@example.com" 
                  {...register("email", {
                    required: { value: true, message: "メールアドレスを入力してください。" },
                    maxLength: { value: 40, message: "40文字以内で入力してください。" },
                    pattern: {
                      value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
                      message: "メールアドレスの形式が正しくありません。"
                    }
                  })}
                />
                {errors.email && <p className="alertarea">{errors.email.message}</p>}
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>現在のパスワード<span>※必須</span></label>
              <span className="label-caution">※8文字以上の半角</span>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <input 
                  id="current_password"
                  type={isRevealCurrentPassword ? "text" : "password"}
                  className={isRevealCurrentPassword ? "input-text name required" : "input-password"}
                  {...register("current_password", {
                    required: { value: true, message: "現在のパスワードを入力してください。" },
                    maxLength: { value: 40, message: "40文字以内で入力してください。" },
                    minLength: { value: 8, message: "8文字以上で入力してください。" },
                    pattern: {
                      value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[%+\-!@#$^&*])[A-Za-z\d%+\-!@#$^&*]{8,}$/,
                      message: "パスワードは全て半角で、英大文字・英小文字・数字・記号(「%」,「+」,「-」など)をそれぞれ一つ以上使用してください。"
                    }
                  })}
                />
                <span
                  role="presentation"
                  onClick={toggleCurrentPassword}
                  className="reveal-password"
                >
                  {isRevealCurrentPassword ? (
                    <i className="fas fa-eye" />
                  ) : (
                    <i className="fas fa-eye-slash" />
                  )}
                </span>
                {errors.current_password && <p className="alertarea">{errors.current_password.message}</p>}
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>新しいパスワード</label>
              <span className="label-caution">変更したい場合のみ入力</span>
              <span className="label-caution">※8文字以上の半角</span>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <input 
                  id="new_password"
                  type={isRevealNewPassword ? "text" : "password"}
                  className={isRevealNewPassword ? "input-text name required" : "input-password"}
                  {...register("new_password", {
                    maxLength: { value: 40, message: "40文字以内で入力してください。" },
                    minLength: { value: 8, message: "8文字以上で入力してください。" },
                    pattern: {
                      value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[%+\-!@#$^&*])[A-Za-z\d%+\-!@#$^&*]{8,}$/,
                      message: "パスワードは全て半角で、英大文字・英小文字・数字・記号(「%」,「+」,「-」など)をそれぞれ一つ以上使用してください。"
                    }
                  })}
                />
                <span
                  role="presentation"
                  onClick={toggleNewPassword}
                  className="reveal-password"
                >
                  {isRevealNewPassword ? (
                    <i className="fas fa-eye" />
                  ) : (
                    <i className="fas fa-eye-slash" />
                  )}
                </span>
                {errors.new_password && <p className="alertarea">{errors.new_password.message}</p>}
              </div>
            </div>
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="edit-login-action-container">
          <button
            type="submit"
            className="btn">
            変更する
          </button>
          <a
            href="/mypages/profile"
            className="btn cancel">
            キャンセル
          </a>
        </div>
      </div>
    </form>
  );
};

export default EditLoginForm;
