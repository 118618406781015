import { PrefCitySelect } from "../../../../components/selects/PrefCitySelect";
import { CheckBoxGroup } from "../../../../components/checkbox/CheckBoxGroup";
import { SalarySelect } from "../../../../components/selects/SalarySelect";
import {
  useCallback,
  useEffect,
  useState,
  Fragment
} from "react";
import { createPortal } from "react-dom";
import {
  CheckboxGroupProps,
  PrefCityValues,
  Salary,
} from "../../../../types/jobPostingSearch";
import { SelectOption } from "../../../../types";
import { fetchCities } from "../../../../api/cities";
import { MultiValue } from "react-select";
import { fetchStoresCount, StoresCountRequestParams } from "../../../../api/stores";
import { useDebouncedKeyword } from "../../../../hooks/useDebouncedKeyword";
import { RailroadLine, fetchRailroadLinesByPrefectureUrlWord } from "../../../../api/railroad_lines";
import { RailroadStation } from "../../../../api/railroad_stations";
import RailloadAndStationSelectorModal from "../railloadLineAndStationSelectorModal";

export type SearchModalProps = {
  keyword: string[];
  defaultPrefCityValues: PrefCityValues;
  jobType: CheckboxGroupProps;
  employmentType: CheckboxGroupProps;
  salary: Salary;
  jobWorkStyle: CheckboxGroupProps;
  feature: CheckboxGroupProps;
  benefit: CheckboxGroupProps;
  jobOccupation: CheckboxGroupProps;
  sharedJobTypeValue?: string;
  sharedKeyword?: string[],
  setSharedPrefCityValues?: React.Dispatch<React.SetStateAction<PrefCityValues>>;
  setSharedJobTypeValue?:  React.Dispatch<React.SetStateAction<string>>;
  setSharedKeyword?:  React.Dispatch<React.SetStateAction<string[]>>;
  isOpen: boolean;
  onClose: () => void;
};

export const SearchModal = ({
  keyword,
  defaultPrefCityValues: {
    url_word,
    city_code
  },
  jobType,
  salary: {
    dropdownOptions,
    defaultValue: {
      salaryType,
      salaryMin,
      salaryMax
    }
  },
  employmentType,
  jobWorkStyle,
  feature,
  benefit,
  jobOccupation,
  sharedJobTypeValue,
  sharedKeyword,
  setSharedPrefCityValues,
  setSharedJobTypeValue,
  setSharedKeyword,
  isOpen,
  onClose
}: SearchModalProps) => {


  const [selectedKeyword, setSelectedKeyword] = useState<string[]>(keyword);
  // NOTE: キーワードの入力値が配列となっており、該当モーダルでの入力値は単数のため、配列の最初の値を取得する
  const debouncedKeyword = useDebouncedKeyword({ keyword: selectedKeyword[0] ?? '' });
  const [searchResultCount, setSearchResultCount] = useState<number>(0);

  const [selectedPrefecture, setSelectedPrefecture] = useState<string>('');
  const [selectedCities, setSelectedCities] = useState<SelectOption[]>([]);

  const [selectedJobTypeValues, setSelectedJobTypeValues] = useState<string[]>(jobType.defaultValues);
  const [selectedEmploymentTypeValues, setSelectedEmploymentTypeValues] = useState<string[]>(employmentType.defaultValues);
  const [selectedJobWorkStyleValues, setSelectedJobWorkStyleValues] = useState<string[]>(jobWorkStyle.defaultValues);
  const [selectedFeatureValues, setSelectedFeatureValues] = useState<string[]>(feature.defaultValues);
  const [selectedBenefitValues, setSelectedBenefitValues] = useState<string[]>(benefit.defaultValues);
  const [selectedJobOccupationValues, setSelectedJobOccupationValues] = useState<string[]>(jobOccupation.defaultValues);

  const [selectedSalaryType, setSelectedSalaryType] = useState(salaryType);
  const [selectedSalaryMin, setSelectedSalaryMin] = useState(salaryMin);
  const [selectedSalaryMax, setSelectedSalaryMax] = useState(salaryMax);

  type SearchStatus = 'searching' | 'resultsDisplayed' | 'initialized' | 'cleared';
  const [searchButtonLabel, setSearchButtonLabel] = useState<SearchStatus>('initialized');
  const [isSearchButtonEnabled, setIsSearchButtonEnabled] = useState<boolean>(false);

  const [isRailroadModalOpen, setIsRailroadModalOpen] = useState(false);
  const [railroadLines, setRailroadLines] = useState<RailroadLine[]>([]);
  const [selectedRailroadLines, setSelectedRailroadLines] = useState<RailroadLine[]>([]);
  const [selectedRailroadStations, setSelectedRailroadStations] = useState<RailroadStation[]>([]);

  const getStatusLabel = () => {
    if (searchButtonLabel === 'searching') {
      return '検索中...';
    } else if (searchButtonLabel === 'resultsDisplayed') {
      return `検索結果 ${searchResultCount}件`;
    } else if (searchButtonLabel === 'initialized' || searchButtonLabel === 'cleared') {
      return '検索する';
    } else {
      return '検索する';
    }
  };

  const handleResetClick = useCallback(() => {
    setSearchButtonLabel('cleared');
    setSearchResultCount(0);
    setSelectedKeyword([]);
    setSelectedPrefecture('');
    setSelectedCities([]);
    setSelectedJobTypeValues([]);
    setSelectedEmploymentTypeValues([]);
    setSelectedJobWorkStyleValues([]);
    setSelectedFeatureValues([]);
    setSelectedBenefitValues([]);
    setSelectedJobOccupationValues([]);
    setSelectedSalaryType('');
    setSelectedSalaryMin('');
    setSelectedSalaryMax('');
    setRailroadLines([]);
    setSelectedRailroadLines([]);
    setSelectedRailroadStations([]);
  },[]);

  //チェックボックスのプルダウン
  const useCheckboxChangeHandler = (setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>) => {
    return useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      setSelectedValues((prevSelectedValues) => {
        const updatedValues = typeof prevSelectedValues === 'string' 
          ? [prevSelectedValues, value] 
          : [...prevSelectedValues, value];
        return checked ? updatedValues : updatedValues.filter((item) => item !== value);
      });
    }, [setSelectedValues]);
  };

  const handleJobTypeChange = useCheckboxChangeHandler(setSelectedJobTypeValues);
  const handleEmploymentTypeChange = useCheckboxChangeHandler(setSelectedEmploymentTypeValues);
  const handleJobWorkStyleChange = useCheckboxChangeHandler(setSelectedJobWorkStyleValues);
  const handleFeatureChange = useCheckboxChangeHandler(setSelectedFeatureValues);
  const handleBenefitChange = useCheckboxChangeHandler(setSelectedBenefitValues);
  const handleJobOccupationChange = useCheckboxChangeHandler(setSelectedJobOccupationValues);

  const onClickFetchRailroadLinesByPrefectureUrlWordApi = async (event: React.FormEvent<Element>) => {
    event.preventDefault();
    const response = await fetchRailroadLinesByPrefectureUrlWord({ url_word: selectedPrefecture });
    setRailroadLines(response.data);
    setIsRailroadModalOpen(true);
  };

  const selectAllJobTypes = (event: React.FormEvent<Element>) => {
    event.preventDefault();
    const allJobTypes = jobType.checkBoxOptions.map(option => option.url_word);
    setSelectedJobTypeValues(allJobTypes);
  };

  const onChangeRailroadLine = (selectedRailroadLine: RailroadLine) => {
    setSelectedRailroadLines((prevRailroadLines) => {
      const isSelected = prevRailroadLines.some((line) => line.id === selectedRailroadLine.id);
      if (isSelected) {
        // 既に選択されている場合は削除
        const stations = selectedRailroadStations.filter(station => 
          !selectedRailroadLine.railroadStations.some(s => s.id === station.id));
        setSelectedRailroadStations(stations);
        return prevRailroadLines.filter((line) => line.id !== selectedRailroadLine.id);
      } else {
        // 未選択の場合は追加
        const stations = selectedRailroadLine.railroadStations.flatMap(station => 
          railroadLines.flatMap(line => 
            line.railroadStations.filter(s => s.id === station.id)
          )
        );
        setSelectedRailroadStations([...selectedRailroadStations, ...stations]);
        return [...prevRailroadLines, selectedRailroadLine];
      }
    });
  };

  const onChangeRailroadStation = (selectedRailroadStation: RailroadStation) => {
    setSelectedRailroadStations((prevRailroadStations) => {
      let stations: RailroadStation[] = [];
      const isSelected = prevRailroadStations.some((station) => station.id === selectedRailroadStation.id);
      if (isSelected) {
        // 既に選択されている場合は削除
        stations = prevRailroadStations.filter((station) => station.stationName !== selectedRailroadStation.stationName);
      } else {
        // 未選択の場合は追加
        const sameNameStations = railroadLines.flatMap((line) =>
          line.railroadStations.filter((station) => station.stationName === selectedRailroadStation.stationName)
        );
        stations = [...prevRailroadStations, ...sameNameStations];
      }

      const line = railroadLines.find(line => line.railroadStations.some(station => station.id === selectedRailroadStation.id));
      if (line) {
        const hasStation = line.railroadStations.some(station => stations.some(s => s.id === station.id));
        setSelectedRailroadLines(prevRailroadLines => {
          const isSelected = prevRailroadLines.some(l => l.id === line.id);
          if (hasStation && !isSelected) {
            return [...prevRailroadLines, line];
          } else if (!hasStation && isSelected) {
            return prevRailroadLines.filter(l => l.id !== line.id);
          }
          return prevRailroadLines;
        });
      }

      return stations;
    });
  };

  const onCloseRailroadStationModal = (event: React.FormEvent<Element>) => {
    event.preventDefault();
    setIsRailroadModalOpen(false);
  };

  //クイック検索対応
  useEffect(() => {
    if(sharedJobTypeValue) {
      //クイック検索の値をモーダル側では配列にする必要がある
      const convertedJobType = [sharedJobTypeValue];
      setSelectedJobTypeValues(convertedJobType);
    }
  },[sharedJobTypeValue]);

  useEffect(() => {
    sharedKeyword && setSelectedKeyword(sharedKeyword);
  },[sharedKeyword]);

  useEffect(() => {
    setSharedPrefCityValues && setSharedPrefCityValues({
      url_word: selectedPrefecture,
      city_code: []
    });
  },[selectedPrefecture, setSharedPrefCityValues]);

  useEffect(() => {
    if (selectedJobTypeValues.length === 1) {
      setSharedJobTypeValue && setSharedJobTypeValue(selectedJobTypeValues[0] ?? '');
    }
  },[selectedJobTypeValues, setSharedJobTypeValue]);

  useEffect(() => {
    setSharedKeyword && setSharedKeyword(selectedKeyword);
  },[selectedKeyword, setSharedKeyword]);

  //都道府県のプルダウン
  useEffect(() => {
    (async (url_word: string, city_code: number | number[]) => {
      if (url_word != '') setSelectedPrefecture(url_word);
      //MEMO: /jobs/pref/tokyo/city/{city_code}に遷移する際に、city_codeが配列で渡ってこない
      const convertedCityCode = Array.isArray(city_code) ? city_code : [city_code];
      if (convertedCityCode.length > 0) {
        const { data } = await fetchCities({ codes: convertedCityCode });
        const citiesDefaultValues = data.map(({ name, code }) => ({
          value: code,
          label: name,
        }));
        setSelectedCities(citiesDefaultValues);
      } else {
        setSelectedCities([]);
      }
    })(url_word, city_code);
  },[url_word, city_code]);

  
  /**
   * 検索条件に変更があった場合、リアルで検索結果件数取得APIをコールする
   */
  useEffect(() => {
    // リクエストパラメーターを作成
    const requestParams: StoresCountRequestParams = {
      keyword: [debouncedKeyword],
      prefecture_url_word: selectedPrefecture || '',
      city_code: selectedCities.map(city => `${city.value}`),
      job_occupation_url_words: selectedJobOccupationValues,
      job_type_url_words: selectedJobTypeValues,
      job_employment_types: selectedEmploymentTypeValues,
      job_salary_type: selectedSalaryType,
      salary_min: selectedSalaryMin,
      salary_max: selectedSalaryMax,
      job_work_style_url_words: selectedJobWorkStyleValues,
      job_feature_url_words: selectedFeatureValues,
      job_benefit_url_words: selectedBenefitValues,
      railroad_line_ids: selectedRailroadLines.map((line) => line.id),
      railroad_station_ids: selectedRailroadStations.map((station) => station.id)
    };

    // 検索条件に変更があった場合、リアルで検索結果件数取得APIをコールする
    const fetchStoresCountApi = async () => {
      const response = await fetchStoresCount(requestParams);
      setSearchResultCount(response.count);
      setSearchButtonLabel('resultsDisplayed');
    };
    fetchStoresCountApi();
  }, [
    debouncedKeyword, selectedPrefecture, selectedCities, selectedJobOccupationValues,
    selectedJobTypeValues, selectedEmploymentTypeValues, selectedSalaryType,
    selectedSalaryMin, selectedSalaryMax, selectedJobWorkStyleValues,
    selectedFeatureValues, selectedBenefitValues, selectedRailroadLines, selectedRailroadStations
  ]);

  /**
   * 検索結果件数が変更された場合、検索ボタンの有効/無効を切り替える
   */
  useEffect(() => {
    setIsSearchButtonEnabled(searchResultCount > 0);
  }, [searchResultCount]);

  //既に選択されている値を引数に、選択されている都道府県と市区町村を取得しプルダウンにセットする
  const handlePrefectureChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPrefecture = e.target.value;
    setSelectedPrefecture(selectedPrefecture);
    setSelectedCities([]);
    setSelectedRailroadLines([]);
    setSelectedRailroadStations([]);
  }, [setSelectedCities, setSelectedPrefecture]);

  const handleCitiesChange = useCallback((selectedOptions: MultiValue<SelectOption>) => {
    setSelectedCities(Array.from(selectedOptions));
  }, [setSelectedCities]);

  //給料のプルダウン
  const handleSalaryTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSalaryType(e.target.value);
  };
  const handleSalaryMin = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSalaryMin(e.target.value);
  };
  const handleSalaryMax = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSalaryMax(e.target.value);
  };

  //アコーディオン
  const [accordionStates, setAccordionStates] = useState({
    jobWorkStyle: true,
    feature: true,
    benefit: true,
    jobOccupation: true
  });

  const handleAccordionToggle = (accordion: keyof typeof accordionStates) => {
    setAccordionStates((prevStates) => ({
      ...prevStates,
      [accordion]: !prevStates[accordion],
    }));
  };

  return (
    <>
      {createPortal(
        <>
          <div
            id="search_condition"
            className={'js-modal modal'}
            css={isOpen ? {
              display: 'block'
            }: {
              display: 'none'
            }}
          >
            <div
              className="modal-bg"
              onClick={onClose}
            ></div>
            <div className="modal-content">
              <div className="in-box">
                <h2 className="modal-title">検索条件を絞り込む</h2>
                <form
                  className="form-search"
                  method="get"
                  action='/jobs'
                >
                  <div className="form-box search-condition-modal">
                    <div className="form-block">
                      <div className="label-block">
                        <label>フリーワード</label>
                      </div>

                      <div className="parts-block">
                        <div className="input-form small">
                          <input
                            type="text"
                            name="keyword[]"
                            value={selectedKeyword}
                            onChange={(e) => setSelectedKeyword([e.target.value])}
                            placeholder="フリーワードを入力"
                            className="input-text"
                            maxLength={40}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-block">
                      <div className="label-block">
                        <label>勤務地</label>
                      </div>
                      <div className="select-block">
                        <PrefCitySelect
                          selectedPrefecture={selectedPrefecture}
                          selectedCities={selectedCities}
                          onPrefectureChange={handlePrefectureChange}
                          onCitiesChange={handleCitiesChange}
                        />
                        <button
                          onClick={(event) => onClickFetchRailroadLinesByPrefectureUrlWordApi(event)}
                          className="button"
                          disabled={selectedPrefecture === ''}
                        >
                          路線・駅を選択
                        </button>
                      </div>
                      {(selectedRailroadLines.length > 0 || selectedRailroadStations.length > 0) && (
                        <ul className="tab-block">
                          {selectedRailroadLines.map((line) => {
                            const allStationsSelected = line.railroadStations.every(station =>
                              selectedRailroadStations.some(s => s.id === station.id)
                            );

                            return allStationsSelected ? (
                              <Fragment key={line.id}>
                                <li>
                                  <button
                                    className="btn-unstyled"
                                    onClick={() => onChangeRailroadLine(line)}
                                  >×</button>
                                  路線：{line.name}
                                </li>
                                <input
                                  type="hidden"
                                  name="railroad_line_ids[]"
                                  value={line.id} />
                              </Fragment>
                            ) : (
                              line.railroadStations
                                .filter(station => selectedRailroadStations.some(s => s.id === station.id))
                                .map((station) => (
                                  <Fragment key={station.id}>
                                    <li>
                                      <button
                                        className="btn-unstyled"
                                        onClick={() => onChangeRailroadStation(station)}
                                      >×</button>
                                      駅：{station.stationName}
                                    </li>
                                    <input
                                      type="hidden"
                                      name="railroad_station_ids[]"
                                      value={station.id} />
                                  </Fragment>
                                ))
                            );
                          })}
                        </ul>
                      )}
                    </div>
                    <div className="form-block">
                      <div
                        className={"label-block"}
                      >
                        <label>職種</label>
                      </div>
                      <CheckBoxGroup
                        selectedValues={selectedJobOccupationValues}
                        onCheckBoxChange={handleJobOccupationChange}
                        checkBoxOptions={jobOccupation.checkBoxOptions}
                        label={jobOccupation.label}
                      />
                    </div>
                    <div className="form-block">
                      <div className="label-block">
                        <label>仕事内容</label>
                        <button
                          onClick={selectAllJobTypes}
                          className="select-all-btn"
                        >全てを選択</button>
                      </div>
                      <CheckBoxGroup
                        selectedValues={selectedJobTypeValues}
                        onCheckBoxChange={handleJobTypeChange}
                        checkBoxOptions={jobType.checkBoxOptions}
                        label={jobType.label}
                      />
                    </div>
                    <div className="form-block">
                      <div className="label-block">
                        <label>雇用形態</label>
                      </div>
                      <CheckBoxGroup
                        selectedValues={selectedEmploymentTypeValues}
                        onCheckBoxChange={handleEmploymentTypeChange}
                        checkBoxOptions={employmentType.checkBoxOptions}
                        label={employmentType.label}
                      />
                    </div>
                    <div className="form-block">
                      <div className="label-block">
                        <label>給与</label>
                      </div>
                      <SalarySelect
                        dropdownOptions={dropdownOptions}
                        selectedSalaryType={selectedSalaryType}
                        selectedSalaryMin={selectedSalaryMin}
                        selectedSalaryMax={selectedSalaryMax}
                        handleSalaryTypeChange={handleSalaryTypeChange}
                        handleSalaryMin={handleSalaryMin}
                        handleSalaryMax={handleSalaryMax}
                      />
                    </div>
                    <div className="form-block">
                      <div
                        className={`label-block modal-accordion-title ${accordionStates.jobWorkStyle ? 'open' : ''}`}
                        onClick={() => handleAccordionToggle('jobWorkStyle')}
                      >
                        <label>勤務体制・休日</label>
                      </div>
                      <CheckBoxGroup
                        selectedValues={selectedJobWorkStyleValues}
                        onCheckBoxChange={handleJobWorkStyleChange}
                        checkBoxOptions={jobWorkStyle.checkBoxOptions}
                        label={jobWorkStyle.label}
                      />
                    </div>
                    <div className="form-block">
                      <div
                        className={`label-block modal-accordion-title ${accordionStates.feature ? 'open' : ''}`}
                        onClick={() => handleAccordionToggle('feature')}
                      >
                        <label>特徴</label>
                      </div>
                      <CheckBoxGroup
                        selectedValues={selectedFeatureValues}
                        onCheckBoxChange={handleFeatureChange}
                        checkBoxOptions={feature.checkBoxOptions}
                        label={feature.label}
                      />
                    </div>
                    <div className="form-block">
                      <div
                        className={`label-block modal-accordion-title ${accordionStates.benefit ? 'open' : ''}`}
                        onClick={() => handleAccordionToggle('benefit')}
                      >
                        <label>福利厚生</label>
                      </div>
                      <CheckBoxGroup
                        selectedValues={selectedBenefitValues}
                        onCheckBoxChange={handleBenefitChange}
                        checkBoxOptions={benefit.checkBoxOptions}
                        label={benefit.label}
                      />
                    </div>
                  </div>
                  <div className="col2-btn">
                    <input
                      type="button"
                      value="クリアする"
                      className="btn ait-no"
                      onClick={handleResetClick}
                    ></input>
                    <input
                      type="submit"
                      disabled={!isSearchButtonEnabled}
                      value={getStatusLabel()}
                      className="btn">
                    </input>
                  </div>
                </form>
                <button
                  className="js-modal-close close-btn btn-unstyled"
                  onClick={onClose}
                >×</button>
              </div>
            </div>
          </div>
          <div className="menu-overlay"></div>
        </>,
        document.body
      )}
      {isRailroadModalOpen && createPortal(
        <RailloadAndStationSelectorModal
          isOpen={isRailroadModalOpen}
          onClose={(event) => onCloseRailroadStationModal(event)}
          railloadLines={railroadLines}
          selectedRailroadLines={selectedRailroadLines}
          selectedRailroadStations={selectedRailroadStations}
          onChangeRailroadLine={onChangeRailroadLine}
          onChangeRailroadStation={onChangeRailroadStation}
        />,
        document.body
      )}
    </>
  );
};
