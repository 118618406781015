import {
  GoogleMap as OriginalGoogleMap,
  LoadScript,
  Marker } from "@react-google-maps/api";
import { useState } from "react";

export type GoogleMapProps = {
  mapApiKey?: string;
  location: {
    lat: number | null;
    lng: number | null;
  }
};

export const ShopGoogleMap = ({
  mapApiKey,
  location: {
    lat,
    lng
  }
}: GoogleMapProps) => {
  const [showMap, setShowMap] = useState(false);

  const mapContainerStyle = {
    height: '400px',
    width: '100%'
  };

  const position = lat && lng ? { lat: lat as number, lng: lng as number } : null;

  return (
    <>
      {position && !showMap &&
        <a
          onClick={() => setShowMap(true)}
          css={{ cursor: 'pointer' }}
        >
          地図を表示する
        </a>
      }
      {mapApiKey && showMap && position &&
        <LoadScript
          googleMapsApiKey={mapApiKey}
        >
          <OriginalGoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={position}
          >
            <Marker position={position} />
          </OriginalGoogleMap>
        </LoadScript>}
    </>
  );
};
