import { useState } from "react";
import { SearchModal } from "../searchModal/SearchModal";
import {
  CheckboxGroupProps,
  PrefCityValues,
  Salary
} from "../../../../types/jobPostingSearch";

export type SearchModalButtonProps = {
  keyword: string[],
  defaultPrefCityValues: PrefCityValues;
  jobType: CheckboxGroupProps;
  employmentType: CheckboxGroupProps;
  salary: Salary;
  jobWorkStyle: CheckboxGroupProps;
  feature: CheckboxGroupProps;
  benefit: CheckboxGroupProps;
  jobOccupation: CheckboxGroupProps;
};

export const SearchModalButton = ({
  keyword,
  defaultPrefCityValues,
  jobType,
  employmentType,
  salary,
  jobWorkStyle,
  feature,
  benefit,
  jobOccupation
}: SearchModalButtonProps) => {

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <button
        onClick={openModal}
        className="search-condition-btn"
        css={{
          appearance: 'none',
          border: 0,
          backgroundColor: 'unset'
        }}
      >条件変更</button>
      <SearchModal
        keyword={keyword}
        defaultPrefCityValues={defaultPrefCityValues}
        jobType={jobType}
        employmentType={employmentType}
        salary={salary}
        jobWorkStyle={jobWorkStyle}
        feature={feature}
        benefit={benefit}
        jobOccupation={jobOccupation}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};
