import { useForm, SubmitHandler } from "react-hook-form";
import { TransformedBasicInfoData, BasicInfoData, postBasicInfo } from "../../../api/users";

const genderOptions = [
  { value: "female", label: "女性" },
  { value: "male", label: "男性" }
];

const BasicInfoForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BasicInfoData>();

  const onSubmit: SubmitHandler<BasicInfoData> = (data) => {
    const currentUrl = new URL(window.location.href);
    const token = currentUrl.searchParams.get("token") || "";

    const birthday = `${data.birthday.year}-${data.birthday.month}-${data.birthday.day}`;
    const formData: TransformedBasicInfoData = {
      token: token,
      name: data.name,
      name_kana: data.name_kana,
      gender: data.gender,
      tel: data.tel,
      birthday: birthday,
      postal_code: data.postal_code,
      address: data.address
    };
    postBasicInfo(formData)
      .then(() => {
        const newUrl = `/users/requirements${token ? `?token=${token}` : ""}`;
        window.location.href = newUrl;
      })
      .catch((error) => {
        console.error("データの登録に失敗しました:", error);
      });
  };

  const range = (start: number, end: number) => 
    Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 60;
  const endYear = currentYear - 15;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="basic-info-headline">
        <h1 className="main-headline">会員登録</h1>
        <p>基本情報を入力してください。</p>
        <div className="form-box">
          <div className="form-block">
            <div className="label-block">
              <label>氏名<span>※必須</span></label>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <input 
                  id="name"
                  type="text"
                  className="input-text name required" 
                  placeholder="例) 瀬良 太郎" 
                  autoComplete="off" 
                  {...register("name", {
                    required: { value: true, message: "氏名を入力してください。" },
                    maxLength: { value: 40, message: "40文字以内で入力してください。" }
                  })}
                />
                {errors.name && <p className="alertarea">{errors.name.message}</p>}
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>カナ<span>※必須</span></label>
              <span className="label-caution">※全角カタカナ</span>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <input 
                  id="name_kana"
                  type="text" 
                  className="input-text name required" 
                  placeholder="例) セラ タロウ" 
                  autoComplete="off" 
                  {...register("name_kana", {
                    required: { value: true, message: "カナを入力してください。" },
                    maxLength: { value: 40, message: "40文字以内で入力してください。" }
                  })}
                />
                {errors.name_kana && <p className="alertarea">{errors.name_kana.message}</p>}
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>性別<span>※必須</span></label>
            </div>
            <div className="parts-block">
              <div className="form-inline-box">
                {genderOptions.map((option) => (
                  <div
                    className="radiobtn"
                    key={option.value}>
                    <input
                      id={`gender_${option.value}`}
                      type="radio"
                      value={option.value}
                      defaultChecked={option.value === "female"}
                      {...register("gender", {
                        required: { value: true, message: "性別を選択してください。" },
                      })}
                    />
                    <label htmlFor={`gender_${option.value}`}>{option.label}</label>
                  </div>
                ))}
                {errors.gender && <p className="alertarea">{errors.gender.message}</p>}
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>電話番号<span>※必須</span></label>
              <span className="label-caution">※半角数字、ハイフン「-」不要</span>
            </div>
            <div className="parts-block">
              <div className="input-form small">
                <input
                  id="tel"
                  type="tel"
                  className="input-tel number required"
                  placeholder="例）09012345678"
                  autoComplete="off"
                  {...register("tel", {
                    required: { value: true, message: "電話番号を入力してください。" },
                    maxLength: { value: 24, message: "24文字以内で入力してください。" }
                  })}
                />
                {errors.tel && <p className="alertarea">{errors.tel.message}</p>}
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>生年月日<span>※必須</span></label>
            </div>
            <div className="parts-block">
              <div className="birth">
                <div className="select-group">
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"></i>
                  <select
                    id="birthday.year"
                    className="select-box required"
                    {...register("birthday.year", {
                      required: { value: true, message: "生年月日を選択してください。" },
                    })}
                  >
                    <option value="">年</option>
                    {range(startYear, endYear).toReversed().map(year => (
                      <option
                        key={year}
                        value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <p className="form-text">年</p>
                <div className="select-group">
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"></i>
                  <select
                    id="birthday.month"
                    className="select-box required"
                    {...register("birthday.month", {
                      required: { value: true, message: "生年月日を選択してください。" },
                    })}
                  >
                    <option value="">月</option>
                    {range(1, 12).map(month => (
                      <option
                        key={month}
                        value={month}>{month}</option>
                    ))}
                  </select>
                </div>
                <p className="form-text">月</p>
                <div className="select-group">
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"></i>
                  <select
                    id="birthday.day"
                    className="select-box required"
                    {...register("birthday.day", {
                      required: { value: true, message: "生年月日を選択してください。" },
                    })}
                  >
                    <option value="">日</option>
                    {range(1, 31).map(day => (
                      <option
                        key={day}
                        value={day}>{day}</option>
                    ))}
                  </select>
                </div>
                <p className="form-text">日</p>
              </div>
              {(errors.birthday?.year || errors.birthday?.month || errors.birthday?.day) && (
                <p className="alertarea">生年月日を選択してください。</p>
              )}
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>郵便番号<span>※必須</span></label>
              <span className="label-caution">※ハイフン「-」不要</span>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <div className="input-form small">
                  <input
                    id="postalCode"
                    type="tel"
                    className="input-tel number required"
                    placeholder="例）0000000"
                    autoComplete="off"
                    {...register("postal_code", {
                      required: { value: true, message: "郵便番号を入力してください。" },
                      maxLength: { value: 24, message: "24文字以内で入力してください。" }
                    })}
                  />
                  {errors.postal_code && <p className="alertarea">{errors.postal_code.message}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="form-block">
            <div className="label-block">
              <label>住所</label>
            </div>
            <div className="parts-block">
              <div className="input-form middle">
                <input 
                  id="address"
                  type="text" 
                  className="input-text name required" 
                  autoComplete="off"
                  {...register("address", {
                    maxLength: { value: 40, message: "40文字以内で入力してください。" }
                  })}
                />
                {errors.address && <p className="alertarea">{errors.address.message}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="basic-info-action-container">
          <button
            type="submit"
            className="btn">
            保存して次へ
          </button>
        </div>
      </div>
    </form>
  );
};

export default BasicInfoForm;
