import { useEffect, useState } from "react";
import { SearchModal } from "../searchModal/SearchModal";
import {
  CheckboxGroupProps,
  PrefCityValues,
  Salary
} from "../../../../types/jobPostingSearch";
import { Prefecture, fetchPrefectures } from "../../../../api/prefectures";

type QuickSearchProps = {
  keyword: string[];
  defaultPrefCityValues: PrefCityValues;
  jobType: CheckboxGroupProps;
  employmentType: CheckboxGroupProps;
  salary: Salary;
  jobWorkStyle: CheckboxGroupProps;
  feature: CheckboxGroupProps;
  benefit: CheckboxGroupProps;
  jobOccupation: CheckboxGroupProps;
};

export const QuickSearch = ({
  keyword,
  defaultPrefCityValues,
  jobType,
  employmentType,
  salary,
  jobWorkStyle,
  feature,
  benefit,
  jobOccupation
}: QuickSearchProps) => {

  //クイック検索対応
  const [prefectureOptions, setPrefectureOptions] = useState<Prefecture[]>([]);
  const [sharedPrefCityValues, setSharedPrefCityValues] = useState<PrefCityValues>(defaultPrefCityValues);
  const [sharedJobTypeValue, setSharedJobTypeValue] = useState<string>('');
  const [sharedKeyword, setSharedKeyword] = useState<string[]>(keyword);
  useEffect(() => {
    (async () => {
      const { data } = await fetchPrefectures();
      setPrefectureOptions(data);
    })();
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="top-search-block">
        <div className="top-search-headline">
          <h2>クイック検索</h2>
        </div>
        {/* クイック検索フォーム */}
        <form
          className={'form-search'}
          method="get"
          action='/jobs'
        >
          <div className="in-top-search">
            <div className="col3-1">
              <div className="select-group">
                <i
                  className="fa fa-chevron-down"
                  aria-hidden="true"></i>
                <select
                  name="prefecture_url_word"
                  className="select-box"
                  value={sharedPrefCityValues.url_word}
                  onChange={(event) => setSharedPrefCityValues({
                    url_word: event.target.value,
                    city_code: []
                  })}
                >
                  <option value=''>都道府県を選択</option>
                  {prefectureOptions.map((pref) => (
                    <option
                      key={pref.urlWord}
                      value={pref.urlWord}
                    >
                      {pref.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-group">
                <i
                  className="fa fa-chevron-down"
                  aria-hidden="true"></i>
                <select
                  name="job_type_url_words"
                  className="select-box"
                  id="job-type-select-quick-search"
                  value={sharedJobTypeValue}
                  onChange={(event) => setSharedJobTypeValue(event.target.value)}
                >
                  <option value=''>仕事内容を選択</option>
                  {jobType.checkBoxOptions.map((job) => (
                    <option
                      key={job.url_word}
                      value={job.url_word}>
                      {job.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col3-2">
              <div className="input-form">
                <input
                  type="text"
                  name="keyword[]"
                  value={sharedKeyword}
                  onChange={(e) => setSharedKeyword([e.target.value])}
                  placeholder="フリーワードを入力"
                  className="input-text"
                  maxLength={40}
                />
              </div>
            </div>
            <div className="col3-3">
              <span
                className={`btn border-btn`}
                onClick={openModal}
                data-target="search_condition"
              >
                もっと
                <br/>
                絞り込む
              </span>
              <input
                type="submit"
                className="btn search-submit"
                value="検索する">
              </input>
            </div>
          </div>
        </form>
        {/* モーダル */}
      </div>
      <SearchModal
        keyword={sharedKeyword}
        sharedKeyword={sharedKeyword}
        setSharedKeyword={setSharedKeyword}
        defaultPrefCityValues={sharedPrefCityValues}
        setSharedPrefCityValues={setSharedPrefCityValues}
        sharedJobTypeValue={sharedJobTypeValue}
        setSharedJobTypeValue={setSharedJobTypeValue}
        jobType={jobType}
        employmentType={employmentType}
        salary={salary}
        jobWorkStyle={jobWorkStyle}
        feature={feature}
        benefit={benefit}
        jobOccupation={jobOccupation}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};
