import { FavoriteJob } from "../../../api/favorite_jobs";
import { JobPostingImage } from '../../../api/job_posting_images';
import { numberToCurrency } from "../../../utils/convert";
import Heart from "../../../components/svg/Heart";

interface FavoriteJobProps {
  favoriteJobs: FavoriteJob[];
  bulkJobPostingUrl: string;
  onClickDeleteFavoriteJob: (favoriteJobId: number) => void;
}

const FavoriteJobComponent = ({ favoriteJobs, bulkJobPostingUrl, onClickDeleteFavoriteJob }: FavoriteJobProps) => {
  const getImageThumbnailUrl = (jobPostingImages: JobPostingImage[]): string | undefined => {
    const sortedImages = jobPostingImages.sort((a, b) => {
      const aSortNo = a.sortNo === null ? Number.MAX_VALUE : a.sortNo;
      const bSortNo = b.sortNo === null ? Number.MAX_VALUE : b.sortNo;
      return aSortNo - bSortNo || a.id - b.id;
    });
    return sortedImages.length ? sortedImages[0].image.thumbnailUrl : undefined;
  };

  return (
    <>
      <div className="icon-title-container">
        <Heart
          width={24}
          height={24}
          fill="#F29F02"
          className="icon"
        />
        <h1 className="main-headline">気になる</h1>
      </div>
      <div className="favorite-result">
        <div className="favorite-result-header">
          {favoriteJobs.length > 0 && (
            <>
              <p className="result-count">
                <span>{favoriteJobs.length}</span>件の気になる求人があります
              </p>
              <a
                href={bulkJobPostingUrl}
                className="btn">
                一括応募する
              </a>
            </>
          )}
        </div>
        {favoriteJobs.length ? (
          favoriteJobs.map((favoriteJob) => (
            <div
              key={favoriteJob.id}
              className="favorite-result-list">
              {getImageThumbnailUrl(favoriteJob.jobPosting.jobPostingImages) && (
                <p className="thumb">
                  <img
                    src={getImageThumbnailUrl(favoriteJob.jobPosting.jobPostingImages)}
                    alt={`${favoriteJob.jobPosting.store.name} ${favoriteJob.jobPosting.title}`} />
                </p>
              )}
              <div className="shop-contents">
                <p className="shop-name">{favoriteJob.jobPosting.store.name}</p>
                <h2>
                  <a
                    href={`/job/${favoriteJob.jobPosting.code}`}
                    className="shop-copy">{favoriteJob.jobPosting.title}</a>
                </h2>
                {/* TODO: 本来であれば求人ステータスが応募停止だった場合には、別のページを表示する制御が必要であるが、現時点では対応しない */}

                <ol className="badge-list">
                  {favoriteJob.jobPosting.jobTypes.map((jobType) => (
                    <>
                      <li
                        key={jobType.id}
                        className="badge">{jobType.name}</li>
                      <span className="recruit_tag"></span>
                    </>
                  ))}
                </ol>

                <div className="detail-contents salary">
                  <h3>給与</h3>
                  {favoriteJob.jobPosting.jobPostingSalaries.map((jobPostingSalary) => (
                    <div
                      key={jobPostingSalary.id}
                      className="salary-detail">
                      <span className="badge">{jobPostingSalary.employmentTypeText}</span>
                      {jobPostingSalary.salaryTypeText}
                      {jobPostingSalary.salaryMin && (
                        <strong>{numberToCurrency(jobPostingSalary.salaryMin)}</strong>
                      )}
                      〜
                      {jobPostingSalary.salaryMax && (
                        <strong>{numberToCurrency(jobPostingSalary.salaryMax)}</strong>
                      )}
                    </div>
                  ))}
                </div>

                <div className="detail-contents">
                  <h3>募集店舗</h3>
                  <p className="shop-name-sm">{favoriteJob.jobPosting.store.name}</p>
                  <p className="shop-place">
                    {favoriteJob.jobPosting.store.prefecture.name}{favoriteJob.jobPosting.store.city.name}
                    {favoriteJob.jobPosting.nearestRailroadStations.length && (
                      <>
                        ({favoriteJob.jobPosting.nearestRailroadStations[0].railroadStation.name}駅
                        {favoriteJob.jobPosting.nearestRailroadStations[0].note})
                      </>
                    )}
                  </p>
                </div>

                <div className="btn-set">
                  <a
                    href={`/job/${favoriteJob.jobPosting.code}`}
                    className="btn border-btn">詳細を見る</a>
                  {favoriteJob.jobPosting.status === 'published' && (
                    <a
                      href={`/application?code=${favoriteJob.jobPosting.code}`}
                      className="btn">
                      <span>最短1分で応募完了！</span>
                      WEBで応募する
                    </a>
                  )}
                  <button
                    onClick={() => onClickDeleteFavoriteJob(favoriteJob.id)}
                    className="btn border-btn">気になるから外す</button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>気になるリストに登録されている求人はありません</div>
        )}
      </div>
    </>
  );
};

export default FavoriteJobComponent;