import { withHttpError } from '../libs/httpRequest/withHttpError';
import { myAxios } from '../libs/httpRequest/myAxios';
import { JobPostingImage } from './job_posting_images';
import { JobType } from './job_types';
import { JobPostingSalary } from './job_posting_salaries';
import { NearestRailroadStation } from './nearest_railroad_stations';
import { Company } from './companies';
import { Prefecture } from './prefectures';

export type FavoriteStore = {
  id: number;
  kyokaiCode: string;
  name: string;
  code: string | null;
  address1: string | null;
  address2: string | null;
  address3: string | null;
  company_id: number | null;
  company: Company | null;
  prefecture: Prefecture;
  city: FavoriteCity;
}

export type FavoriteCity = {
  id: number;
  name: string;
  code: string;
}

// 求人のステータス
// suspended_application: 応募停止、published: 公開中、hidden: 非公開
type JobPostingStatus = 'suspended_application' | 'published' | 'hidden';

type JobPosting = {
  id: number;
  code: string;
  storeId: number;
  accountId: number | null;
  status: JobPostingStatus;
  title: string;
  salaryText: string | null;
  workText: string | null;
  workdayHolidayText: string | null;
  benefitText: string | null;
  qualificationText: string | null;
  messageText: string | null;
  trainingProgramText: string | null;
  companyText: string | null;
  applicationCompletedEmailText: string | null;
  selectionFlowText: string | null;
  searchText: string;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  isPostalCodeRequired: boolean;
  score: number;
  store: FavoriteStore,
  jobPostingImages: JobPostingImage[];
  jobTypes: JobType[];
  jobPostingSalaries: JobPostingSalary[];
  nearestRailroadStations: NearestRailroadStation[];
}

export type FavoriteJob = {
  id: number;
  jobPostingId: number;
  userSessionId: string;
  jobPosting: JobPosting;
}

// 気になるリスト取得APIのレスポンス
export type FavoriteJobsResponse = {
  data: FavoriteJob[];
}

export type FavoriteJobResponse = {
  data: FavoriteJob;
}

type FavoriteJobsCount = {
  count: number;
}

// 気になるリストの件数取得APIのレスポンス
export type FavoriteJobsCountResponse = {
  data: FavoriteJobsCount;
}

// 気になるリスト登録APIのリクエスト
export type FavoriteJobsRequestParams = {
  job_posting_id: number;
}

export const fetchFavoriteJobs = async (): Promise<FavoriteJobsResponse> => {
  const response = await withHttpError<FavoriteJobsResponse>(async () => {
    return await myAxios.get('/api/v1/favorite_jobs/get_favorite_jobs');
  });
  return response.data;
};

export const fetchFavoriteJobsCount = async (): Promise<FavoriteJobsCountResponse> => {
  const response = await withHttpError<FavoriteJobsCountResponse>(async () => {
    return await myAxios.get('/api/v1/favorite_jobs/get_favorite_jobs_count');
  });
  return response.data;
};

export const registerFavoriteJob = async (params: FavoriteJobsRequestParams): Promise<FavoriteJobResponse> => {
  const response = await withHttpError(async () => {
    return await myAxios.post('/api/v1/favorite_jobs/post_favorite_job', params);
  });
  return response.data;
};

export const deleteFavoriteJob = async (favoriteJobId: number): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.delete(`/api/v1/favorite_jobs/delete_favorite_job/${favoriteJobId}`);
  });
};