import { withHttpError } from '../libs/httpRequest/withHttpError';
import { myAxios } from '../libs/httpRequest/myAxios';

export type LoginData = {
  email: string;
  password: string;
}

export const postLogin = async (params: LoginData): Promise<void> => {
  await withHttpError(async () => {
    return await myAxios.post('/api/v1/users/login', params);
  });
};