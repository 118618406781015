import { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { fetchCities } from "../../../api/cities";
import { Prefecture, fetchPrefectures } from "../../../api/prefectures";
import { postRegistration, RegistrationData } from "../../../api/users";
import { SelectOption } from "../../../types";

const employmentTypeOptions = [
  { value: 1, label: "アルバイト" },
  { value: 2, label: "正社員" },
  { value: 3, label: "契約社員" },
  { value: 4, label: "業務委託" }
];

const workExperienceOptions = [
  { value: "no_experience", label: "未経験" },
  { value: "less_than_one_year", label: "実務経験1年未満" },
  { value: "one_to_three_years", label: "実務経験1年以上3年未満" },
  { value: "more_than_three_years", label: "実務経験3年以上" },
  { value: "more_than_five_years", label: "実務経験5年以上" },
  { value: "more_than_ten_years", label: "実務経験10年以上" }
];

const isReceiveScoutMailOptions = [
  { value: 1, label: "受け取る" },
  { value: 0, label: "受け取らない" }
];

const RequirementsForm = () => {
  const {
    register,
    watch,
    handleSubmit,
    setValue
  } = useForm<RegistrationData>({
    defaultValues: {
      is_receive_scout_mail: 1
    }
  });

  const employmentTypes = watch("employment_type_ids") || [];
  const prefectureIds = watch("prefecture_ids") || [];
  const isReceiveScoutMail = watch("is_receive_scout_mail");

  const onSubmit: SubmitHandler<RegistrationData> = (data) => {
    const formData: RegistrationData = {
      prefecture_ids: data.prefecture_ids,
      city_ids: data.city_ids?.flat().map(id => Number(id)),
      employment_type_ids: data.employment_type_ids,
      work_experience: data.work_experience,
      is_receive_scout_mail: data.is_receive_scout_mail
    };
    postRegistration(formData)
      .then(() => {
        window.location.href = "/users/registration_complete";
      })
      .catch((error) => {
        console.error("データの登録に失敗しました:", error);
      });
  };

  const [prefectureOptions, setPrefectureOptions] = useState<Prefecture[]>([]);

  const [prefectureCityPairs, setPrefectureCityPairs] = useState<{
    selectedPrefectureId: number | undefined;
    selectedCities: SelectOption[];
    cityOptions: SelectOption[];
  }[]>([{ selectedPrefectureId: undefined, selectedCities: [], cityOptions: [] }]);

  const updatePrefectureCityPairs = (index: number, newData: Partial<typeof prefectureCityPairs[0]>) => {
    setPrefectureCityPairs((prevPairs) => {
      const newPairs = [...prevPairs];
      newPairs[index] = { ...newPairs[index], ...newData };
      return newPairs;
    });
  };

  const clearSelectedCities = (index: number) => {
    setPrefectureCityPairs((prevPairs) => {
      const newPairs = [...prevPairs];
      newPairs[index] = { ...newPairs[index], selectedCities: [] };
      return newPairs;
    });
  };

  const addPrefectureCityPair = () => {
    setPrefectureCityPairs((prevPairs) => [
      ...prevPairs,
      { selectedPrefectureId: undefined, selectedCities: [], cityOptions: [] }
    ]);
  };

  const handlePrefectureChange = useCallback((index: number) => async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPrefectureId = Number(e.target.value);
    updatePrefectureCityPairs(index, { selectedPrefectureId });
  
    if (selectedPrefectureId) {
      const { data } = await fetchCities({ prefecture_id: selectedPrefectureId });
      const cityOptionsData = data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
  
      updatePrefectureCityPairs(index, { cityOptions: cityOptionsData, selectedCities: cityOptionsData });
    } else {
      updatePrefectureCityPairs(index, { cityOptions: [], selectedCities: [] });
    }
  }, []);

  const handleCitiesChange = useCallback((index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const numValue = Number(value);

    const option = prefectureCityPairs[index].cityOptions.find(option => option.value === numValue);
    if (!option) return;

    updatePrefectureCityPairs(index, {
      selectedCities: checked
        ? [...prefectureCityPairs[index].selectedCities, option]
        : prefectureCityPairs[index].selectedCities.filter(city => city.value !== numValue)
    });
  }, [prefectureCityPairs]);

  const handleEmploymentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const numValue = Number(value);

    setValue(
      "employment_type_ids",
      checked
        ? [...employmentTypes, numValue]
        : employmentTypes.filter((type) => type !== numValue)
    );
  };

  const handleRecieveScoutMailChange = (value: 0 | 1) => {
    setValue("is_receive_scout_mail", value);
  };

  useEffect(() => {
    (async () => {
      const { data } = await fetchPrefectures();
      setPrefectureOptions(data);
    })();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="requirements-headline">
        <div className="requirements-form-container">
          <h1 className="main-headline">会員登録</h1>
          <p>応募条件を入力してください。</p>
          <div className="form-box">
            <div className="form-block">
              <div className="label-block">
                <label>希望勤務エリア</label>
              </div>
              <div className="parts-block prefecture-city-selection">
                {prefectureCityPairs.map((pair, index) => (
                  <div
                    className="prefecture-city-pair"
                    key={index}>
                    <div className="select-group middle">
                      <i
                        className="fa fa-chevron-down"
                        aria-hidden="true"></i>
                      <select
                        id={`prefecture_ids.${index}`}
                        value={pair.selectedPrefectureId}
                        className="select-box required"
                        {...register(`prefecture_ids.${index}`, {
                          onChange: handlePrefectureChange(index),
                          valueAsNumber: true
                        })}
                      >
                        <option value="">選択してください</option>
                        {prefectureOptions
                          .filter(prefecture => !prefectureIds.includes(prefecture.id) || 
                            prefecture.id === pair.selectedPrefectureId
                          )
                          .map((prefecture) => (
                            <option
                              key={prefecture.name}
                              value={prefecture.id}
                            >
                              {prefecture.name}
                            </option>
                          ))}
                      </select>
                      {pair.cityOptions.length > 0 && (
                        <div className="city-selection">
                          <button
                            type="button"
                            onClick={() => clearSelectedCities(index)}
                          >
                            全てのチェックを外す
                          </button>
                          <div className="form-inline-box equal requirements">
                            {pair.cityOptions.map((item) => (
                              <div
                                className="checkbox checkbox-btn"
                                key={item.value}>
                                <input
                                  id={`${item.value}.${index}`}
                                  type="checkbox"
                                  value={item.value}
                                  checked={pair.selectedCities.some(city => city.value === item.value)}
                                  className="checkbox-input"
                                  {...register(`city_ids.${index}`, {
                                    onChange: handleCitiesChange(index)
                                  })}
                                />
                                <label
                                  htmlFor={`${item.value}.${index}`}
                                  className="checkbox-label"
                                >
                                  {item.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {prefectureCityPairs.length > 0 && prefectureCityPairs.at(-1)?.selectedPrefectureId && (
                  <button
                    type="button"
                    className="btn"
                    onClick={addPrefectureCityPair}>
                    都道府県を追加する
                  </button>
                )}
              </div>
            </div>
            <div className="form-block">
              <div className="label-block">
                <label>希望雇用形態</label>
              </div>
              <div className="parts-block">
                <div className="form-inline-box">
                  {employmentTypeOptions.map((option, index) => (
                    <div
                      className="checkbox checkbox-btn"
                      key={option.value}>
                      <input
                        id={`employment_type_ids.${index}`}
                        name={`employment_type_ids.${index}`}
                        type="checkbox"
                        value={option.value}
                        checked={employmentTypes.includes(option.value)}
                        className="checkbox-input"
                        onChange={handleEmploymentTypeChange}
                      />
                      <label
                        htmlFor={`employment_type_ids.${index}`}
                        className="checkbox-label"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="form-block">
              <div className="label-block">
                <label>経験</label>
              </div>
              <div className="parts-block">
                <div className="select-group">
                  <i
                    className="fa fa-chevron-down"
                    aria-hidden="true"></i>
                  <select
                    id="work_experience"
                    className="select-box required"
                    {...register("work_experience")}
                  >
                    <option value="">選択してください</option>
                    {workExperienceOptions.map(option => (
                      <option
                        key={option.value}
                        value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-block">
              <div className="label-block">
                <label>スカウトメール</label>
              </div>
              <div className="parts-block">
                <div className="form-inline-box">
                  {isReceiveScoutMailOptions.map((option) => (
                    <div
                      className="radiobtn"
                      key={option.value}>
                      <input
                        id={`is_receive_scout_mail_${option.value}`}
                        type="radio"
                        value={option.value}
                        checked={Number(isReceiveScoutMail) === option.value}
                        {...register("is_receive_scout_mail", { valueAsNumber: true })}
                        onChange={() => handleRecieveScoutMailChange(option.value as 0 | 1)}
                      />
                      <label
                        htmlFor={`is_receive_scout_mail_${option.value}`}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="requirements-action-container">
          <p><a
            className="auth-terms"
            href="https://www.relaxation-net.jp/privacy-2">利用規約・個人情報保護方針</a>
            をご同意の上、よろしければ「送信して完了」ボタンをクリックしてください。</p>
          <button
            type="submit"
            className="btn"
          >
            送信して完了
          </button>
        </div>
      </div>
    </form>
  );
};

export default RequirementsForm;
