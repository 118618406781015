import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/site/style.scss';
import { rewrap } from '@sonicgarden/rewrap';
import { PrefCitySelect } from '../src/components/selects/PrefCitySelect';
import { SalarySelect } from '../src/components/selects/SalarySelect';
import { ShopGoogleMap } from '../src/features/site/jobPostingApplication/map/ShopGoogleMap';
import { SearchModalButton } from '../src/features/site/jobs/searchModal/SearchModalButton';
import { QuickSearch } from '../src/features/site/jobs/quickSearch/QuickSearch';
import { Toast } from '../src/components/toast/Toast';
import FavoriteJobComponent from '../src/features/site/favoriteJobs';
import FavoriteJobRegisterButton from '../src/components/button/favoriteJobs';
import FavoriteJobLink from '../src/components/link/favoriteJobs';
import RailloadAndStationSelectorModal from '../src/features/site/jobs/railloadLineAndStationSelectorModal';
import SignUpForm from '../src/features/site/users/SignUpForm';
import BasicInfoForm from '../src/features/site/users/BasicInfoForm';
import EditBasicInfoForm from '../src/features/site/users/EditBasicInfoForm';
import RequirementsForm from '../src/features/site/users/RequirementsForm';
import EditRequirementsForm from '../src/features/site/users/EditRequirementsForm';
import SignInForm from '../src/features/site/sessions/SignInForm';
import EditLoginForm from '../src/features/site/sessions/EditLoginForm';
import NavigationMenu from '../src/features/site/mypages/NavigationMenu';
import MyPageTitle from '../src/features/site/mypages/MyPageTitle';
import Profile from '../src/features/site/mypages/Profile';
import StoreDetailComponent from '../src/features/site/stores';
import AuthLink from '../src/components/link/auth';

const element = document.getElementsByTagName('body');
element[0].style.display = 'block';

rewrap('pref-city-select', PrefCitySelect);
rewrap('salary-select', SalarySelect);
rewrap('google-map', ShopGoogleMap);
rewrap('quick-search', QuickSearch);
rewrap('search-modal-button', SearchModalButton);
rewrap('site-toast', Toast);
rewrap('favorite-job-component', FavoriteJobComponent);
rewrap('favorite-job-register-button', FavoriteJobRegisterButton);
rewrap('favorite-job-link', FavoriteJobLink);
rewrap('railload-station-selector-modal', RailloadAndStationSelectorModal);
rewrap('sign-up-form', SignUpForm);
rewrap('basic-info-form', BasicInfoForm);
rewrap('edit-basic-info-form', EditBasicInfoForm);
rewrap('requirements-form', RequirementsForm);
rewrap('edit-requirements-form', EditRequirementsForm);
rewrap('sign-in-form', SignInForm);
rewrap('edit-login-form', EditLoginForm);
rewrap('navigation-menu', NavigationMenu);
rewrap('my-page-title', MyPageTitle);
rewrap('profile-page', Profile);
rewrap('store-detail-component', StoreDetailComponent);
rewrap('auth-link', AuthLink);

// site.ts

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

$(function() {
  const $header = $('#top-head');
  // Nav Toggle Button
  $('#nav-toggle').on('click', function() {
    $header.toggleClass('open');
    $('.menu-overlay').toggleClass('ov-block');
  });
  $('.menu-overlay').on('click', function() {
    $header.removeClass('open');
    $('.menu-overlay').removeClass('ov-block');
  });
});

// viewport
$(function($) {
  const changeViewport = function() {
    const winW = $(window).width();
    let param: string;
    if (typeof winW === 'number' && winW < 765) {
      param = 'width=device-width, initial-scale=1.0, maximum-scale=1.0';
    } else {
      param = 'width=1180';
    }
    $('meta[name="viewport"]').attr('content', param);
  };
  changeViewport();

  $(window).on('resize', function() {
    changeViewport();
  });
});

// スムーススクロール
$(function() {
  $('a[href^="#"]').on('click', function() {
    const speed = 500;
    const href = $(this).attr("href");
    const target = $(href && href !== "#" ? href : 'html');
    const position: number = (target as any).offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

// アコーディオン（スマホのみ）
$(function() {
  if (window.matchMedia("(max-width: 576px)").matches) {
    // .js-accordion-spを付与した箇所
    $('.js-accordion-sp').on('click', function() {
      $(this).next().slideToggle('fast');
      $(this).toggleClass('open');
    });
  }
});

// 応募form
$(function() {
  const initialSelectedValue = $('#work-experience-selector').val();
  if((initialSelectedValue == 'no_experience') || (initialSelectedValue == '')) {
    $('#work-experience-contents-block').addClass('work-experience-contents-block');
  } else {
    $('#work-experience-contents-block').removeClass('work-experience-contents-block');
  }
});

$(function() {
  $('#work-experience-selector').on('change', function() {
    const selectedValue = $(this).val();
    if((selectedValue == 'no_experience') || (selectedValue == '')) {
      $('#work-experience-contents-block').addClass('work-experience-contents-block');
    } else {
      $('#work-experience-contents-block').removeClass('work-experience-contents-block');
    }
  });
});

// トップページ: 新着の求人のスライダー
$(function() {
  $('.slider-block').css('height','auto');
  $('.slider-block').css('visibility','visible');
});

$(function() {
  $('.slider-search').slick({
    arrows: false,
    autoplay: false,
    adaptiveHeight: true,
    dots: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});

// 求人詳細: スライダー
$(function() {
  $('.slider-job-posting').slick({
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    autoplay: true,
    adaptiveHeight: true,
    dots: true,
    slidesToShow: 1,
  });
});


//求人詳細: もっと見る
$(function() {
  const truncateLines = 10;

  $('.add-contents').each(function() {
    const $this = $(this);
    const $fullText = $this.html();
    const $truncatedText = $this.html().split('<br>').splice(0, truncateLines).join('<br>');

    if ($fullText.split('<br>').length > truncateLines) {
      $this.empty().append($truncatedText);
      $this.append('<a href="#" class="show-more-link">... もっと見る</a>');

      $this.on('click', '.show-more-link', function(e) {
        e.preventDefault();
        if ($this.hasClass('expanded')) {
          $this.empty().append($truncatedText);
        } else {
          $this.html($fullText);
        }
        $this.toggleClass('expanded');
      });
    }
  });
});

// 求人応募フォーム: 応募するボタン
$(function() {
  const form = $('#job-posting-applications-form');
  $('#job-posting-applications-submit').on('click', function(e) {
    e.preventDefault();
    $(e.currentTarget).prop('disabled', true);
    // フォームを送信
    form.trigger('submit');
  });
});