import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginData, postLogin } from "../../../api/sessions";

const SignInForm = () => {
  const [error, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginData>();

  const onSubmit: SubmitHandler<LoginData> = (data) => {
    postLogin(data)
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        if (error.status === 403) {
          setError(error.message);
        } else {
          setError("メールアドレスまたはパスワードが正しくありません。");
        }
      });
  };

  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword(!isRevealPassword);
  };

  return (
    <div className="auth-headline">
      <h2>ログイン</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="auth-form-container">
          <div className="auth-form-block">
            <div className="auth-label-block">
              <label>メールアドレス<span>※必須</span></label>
            </div>
            <div className="input-form">
              <input
                type="text"
                className="input-text"
                placeholder="taro@example.com"
                {...register("email", {
                  required: { value: true, message: "メールアドレスを入力してください。" },
                  maxLength: { value: 40, message: "40文字以内で入力してください。" },
                  pattern: {
                    value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
                    message: "メールアドレスの形式が正しくありません。"
                  }
                })}
              />
            </div>
            {errors.email && <p className="alertarea">{errors.email.message}</p>}
          </div>
          <div className="auth-form-block">
            <div className="auth-label-block">
              <label>パスワード<span>※必須</span></label>
            </div>
            <div className="input-form">
              <input
                type={isRevealPassword ? "text" : "password"}
                className={isRevealPassword ? "input-text" : "input-password"}
                {...register("password", {
                  required: { value: true, message: "パスワードを入力してください。" },
                  maxLength: { value: 40, message: "40文字以内で入力してください。" },
                  minLength: { value: 8, message: "8文字以上で入力してください。" },
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[%+\-!@#$^&*])[A-Za-z\d%+\-!@#$^&*]{8,}$/,
                    message: "パスワードは全て半角で、英大文字・英小文字・数字・記号(「%」,「+」,「-」など)をそれぞれ一つ以上使用してください。"
                  }
                })}
              />
              <span
                role="presentation"
                onClick={togglePassword}
                className="reveal-password"
              >
                {isRevealPassword ? (
                  <i className="fas fa-eye" />
                ) : (
                  <i className="fas fa-eye-slash" />
                )}
              </span>
            </div>
            <p>※8文字以上の半角で入力してください。</p>
            {errors.password && <p className="alertarea">{errors.password.message}</p>}
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="auth-action-container">
          <button
            type="submit"
            className="btn sign-in"
          >
            ログイン
          </button>
          <a href="/users/new">
            <span className="auth-highlight">会員登録はこちら</span>
          </a>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;