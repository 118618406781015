import React from 'react';

interface AuthLinkProps {
  isLogin: boolean;
  onClickLogout: () => void;
}

const AuthLink = ({ isLogin, onClickLogout }: AuthLinkProps) => {
  return (
    <>
      {isLogin ? (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <a href="/mypages" className="auth-link">マイページ</a>
            <a href="#" onClick={onClickLogout}>ログアウト</a>
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <a href="/users/new" className="auth-link">会員登録</a>
          <a href="/sessions/new">ログイン</a>
        </div>
      )}
    </>
  );
};

export default AuthLink;